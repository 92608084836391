const formatDate = (date, time = false) => {
    if (! typeof date.getFullYear === 'function' ) {
        return date;
    }
    const fixedChars = (val, num = 2) => {
        val = val.toString();
        if (val.length < num ) {
            let pad = '';
            let add = (num - val.length);
            for(let i=1;i<=add;i++) {
                pad += '0';
            }
            return `${pad}${val}`
        }
        return val;
    }
    let y = fixedChars(date.getFullYear(), 4),
        m = fixedChars(date.getMonth() + 1),
        d = fixedChars(date.getDate());

    let h = fixedChars(date.getHours()),
        i = fixedChars(date.getMinutes()),
        s = fixedChars(date.getSeconds());

    let response = `${d}.${m}.${y}`

    if( !!time ) {
        response += ` ${h}:${i}:${s}`
    }
    return response;
}

export default formatDate;