import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import FullscreenJobView from '../../../Views/Common/FullscreenJobView';
import UserWidget from '../../../Views/Users/UserWidget';
import GiveFeedback from '../../Users/GiveFeedback';

const Paid = ({ job }) => {
    const assignee = useSelector(state => (state.jobs.interestedUsers[job.id] ? state.jobs.interestedUsers[job.id][job.assigned_to] : false))
    const me = useSelector(state => state.user.userData);

    if (!assignee) return null;

    return (
        <FullscreenJobView>
            <Grid item xs={6}>
                <UserWidget uid={me.uid} />
            </Grid>
            <Grid item xs={6}>
                <UserWidget uid={assignee.id} />
            </Grid>
            <Grid item xs={12} style={{ color: '#fff', textAlign: 'left' }}>
                <Typography variant="h4" gutterBottom>Jobben er fullført!</Typography>
                <Typography variant="body1" gutterBottom>
                    Hvordan syntes du det var å jobbe med {assignee.user.displayName}? Gi stjerner nedenfor.
                </Typography>

            </Grid>
            <Grid item xs={12} style={{ color: '#fff' }}>
                <GiveFeedback userId={job.assigned_to} job={job} />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    component={NavLink}
                    to="/"
                >
                    Gå til forsiden
                </Button>
            </Grid>
        </FullscreenJobView>
    )
    // return (
    //     <BottomBar>
    //         <Grid item xs={6}>
    //             <GiveFeedback userId={job.assigned_to} job={job} />
    //         </Grid>
    //         <Grid item xs={6}>
    //             <Button
    //                 variant="contained"
    //                 color="primary"
    //                 component={NavLink}
    //                 to="/"
    //             >
    //                 Gå til forsiden
    //             </Button>
    //         </Grid>
    //     </BottomBar>
    // )
}

export default Paid;