const categories = {
    "gardening": {
        key: "gardening",
        label: "Hagearbeid",
        icon: ""
    },
    "cleaning-serving": {
        key: "cleaning-serving",
        label: "Rengjøring / servering",
        icon: ""
    },
    "pc-it-phone": {
        key: "pc-it-phone",
        label: "PC / IT / Telefon",
        icon: ""
    },
    "car-boat": {
        key: "car-boat",
        label: "Bil og Båt",
        icon: ""
    },
    "animal-care": {
        key: "animal-care",
        label: "Dyrestell",
        icon: ""
    },
    "babysitting": {
        key: "babysitting",
        label: "Barnevakt",
        icon: ""
    }
}

const jobs = {
    "gardening": {
        "lawn-mowing": {
            key: "lawn-mowing",
            label: "Plenklipp"
        },
        "lawn-mowing-outline": {
            key: "lawn-mowing-outline",
            label: "Plenklipp + kantklipp"
        },
        "raking": {
            key: "raking",
            label: "Rake hage"
        },
        "painting": {
            key: "painting",
            label: "Små maleoppdrag"
        },
        "weeding": {
            key: "weeding",
            label: "Luking"
        },
        "snow-shoveling": {
            key: "snow-shoveling",
            label: "Snømåking"
        },
    },
    "cleaning-serving": {
        "indoor-cleaning": {
            key: "indoor-cleaning",
            label: "Innendørs husvask"
        },
        "vacuuming": {
            key: "vacuuming",
            label: "Støvsuging"
        },
        "window-washing": {
            key: "window-washing",
            label: "Vindusvask"
        },
        
        "outdoor-cleaning": {
            key: "outdoor-cleaning",
            label: "Utendørs husvask"
        },
    },
    "pc-it-phone": {
        "help-with-phone-tablet": {
            key: "help-with-phone-tablet",
            label: "Hjelp med telefon / nettbrett"
        },
        "help-with-pc": {
            key: "help-with-pc",
            label: "Hjelp med PC"
        },
        "help-with-tv": {
            key: "help-with-tv",
            label: "Hjelp med TV"
        },
    },
    "car-boat": {
        "interior-cleaning": {
            key: "interior-cleaning",
            label: "Innvendig vask"
        },
        "exterior-cleaning": {
            key: "exterior-cleaning",
            label: "Utvendig vask"
        },
        "polering": {
            key: "polering",
            label: "Polering"
        },
        "stoffing": {
            key: "stoffing",
            label: "Stoffing av båt"
        },
    },
    "animal-care": {
        "dog-walking": {
            key: "dog-walking",
            label: "Gå tur med hund"
        },
    },
    "babysitting": {
        "babysitting": {
            key: "babysitting",
            label: "Sitte barnevakt"
        },
    },
}

export default categories;
export { jobs }