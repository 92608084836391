import { Box } from "@material-ui/core";
import logo from '../assets/ungcash.png';
import logo_white from '../assets/ungcash_white.png';

const Logo = (props) => {
    const {
        style,
        variant = 'normal'
    } = props;
    let output = logo;
    if( variant === 'white' ) {
        output = logo_white;
    }
    return ( 
        <Box
            style={{
                ...{
                    marginBottom: 50,
                    textAlign: 'center'
                },
                ...style
            }}
        >
            <img src={output} alt="UngCash" />
        </Box>
     );
}
 
export default Logo;