import { Badge, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import FullscreenJobView from '../../../Views/Common/FullscreenJobView';
import UserWidget from '../../../Views/Users/UserWidget';

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        textAlign: 'center'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
        minWidth: 120,
    },
}));


const Assigned = ({ job }) => {
    const classes = useStyles();
    const me = useSelector(state => state.user.userData);
    // const { id = false, action = 'view' } = useParams();
    const assignee = useSelector(state => (state.jobs.interestedUsers[job.id] ? state.jobs.interestedUsers[job.id][job.assigned_to] : false))
    if (!assignee) return null;

    let unread = 0;
    if (
        assignee.chat
        && assignee.chat.count
        && assignee.chat.count > 0
        && (
            !assignee.chat[me.uid]
            || assignee.chat.count > assignee.chat[me.uid]
        )
    ) {
        unread = (assignee.chat.count - (assignee.chat[me.uid] || 0));
    }


    return (
        <FullscreenJobView>
            <Grid item xs={6}>
                <UserWidget uid={me.uid} />
            </Grid>
            <Grid item xs={6}>
                <UserWidget uid={assignee.id} />
            </Grid>
            <Grid item xs={12} style={{ color: '#fff', textAlign: 'left' }}>

                <Typography variant="h4" gutterBottom>Du har gitt jobben til {assignee.user.displayName}!</Typography>
                <Typography variant="body1" gutterBottom>
                    Du kan kommunisere med {assignee.user.displayName} ved å trykke på meldinger knappen nedenfor.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Når jobben er utført, skal {assignee.user.displayName} merke av og sende forespørsel om betaling fra sin app.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Badge
                    badgeContent={unread}
                    max={9}
                    color="error"
                    style={{ width: '100%' }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        aria-label="open chat"
                        component={NavLink}
                        to={`/${job.id}/chat/${job.assigned_to}`}
                        fullWidth
                        size="large"
                    >
                        <ChatIcon className={classes.extendedIcon} />
                        Meldinger
                    </Button>
                </Badge>
            </Grid>
        </FullscreenJobView>
    )
}

export default Assigned