
import React from 'react';
import { useSelector } from 'react-redux';
import ContactInfo from './ContactInfo';
import Terms from './Terms';
import EmployerTerms from './Terms/Employer';
import EmployeeTerms from './Terms/Employee';

const Signup = ({children}) => {
    const user = useSelector(state => ({ ...state.user.user, ...state.user.userData }));
    if(
        !user.firstName
        || !user.lastName
        || !user.displayName
        || !user.address
        || !user.postCode
        || !user.postArea
        || !user.birthDate
        // || true !== false
    ) {
        return <ContactInfo user={user} saveText={`Neste`} />
    }
    let termsVersion = EmployerTerms.version;
    if( user.type === 'employee') {
        termsVersion = EmployeeTerms.version;
    }
    if(
        !user.terms
        || user.terms.version < termsVersion
    ) {
        return <Terms />
    }
    return children;
}
 
export default Signup;