import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import CenterFrame from '../../Views/CenterFrame';

const ListEmpty = () => {
    return (
        <CenterFrame>
            <Box style={{textAlign: 'left'}}>
                <Typography variant="h1" gutterBottom>Velkommen til Ungcash!</Typography>

                <Typography variant="body1" gutterBottom>
                    Ungcash er en markedsplass som knytter sammen huseiere med lokal ungdom for å få utført småjobber i og rundt huset.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Du får gjort småjobbene til en rimelig pris og ungdommen får mulighet til å tjene penger og bygge arbeidserfaring. En såkalt vinn-vinn!
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Klikk på knappen nedenfor for å komme i gang med å legge ut din første jobb!
                </Typography>
            </Box>
            
            <Button
                variant="contained"
                color="primary"
                component={NavLink}
                to="/create/"
                style={{
                    marginTop: 10,
                    marginBottom: 20,
                }}
            >
                Legg ut jobb
            </Button>

                <Card>
                    <CardContent style={{textAlign: 'left'}}>
                        <Typography variant="h2" gutterBottom>Slik fungerer det</Typography>

                        <Typography variant="body1" gutterBottom>
                            1: Du oppretter en annonse for jobben du ønsker utført.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            2: Ungdom i området får mulighet til å søke på jobben, og dere kan sende meldinger til hverandre for å avtale nærmere før du velger hvem som får jobben.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            3: Når jobben er utført, Vippser du pengene til ungdomen som har gjort jobben. 
                        </Typography>
                    </CardContent>
                </Card>
        </CenterFrame>
    )
}

export default ListEmpty;