import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

const Draft = ({job}) => {
    const { id = false, action = 'view' } = useParams();

    if (action !== 'view') return null; 
    return (
        <Grid container spacing={3} style={{ marginBottom: 20 }}>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={NavLink}
                    to={`/${id}/edit`}
                >
                    Endre
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={NavLink}
                    to={`/${id}/publish`}
                >
                    Legg ut
                </Button>
            </Grid>
        </Grid>
    )
}

export default Draft