import { AppBar, FormControl, IconButton, Input, InputAdornment, makeStyles, Toolbar } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import firebase from '../../config/firebase';
import tracker from '../../Helpers/tracker';

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    messagesWrapper: {
        height: '100%',
        overflow: 'auto'
    },
    message: {
        width: 'auto',
        maxWidth: '60%',
        borderRadius: 30,
        background: '#eeeeee',
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        padding: '7px 15px',
        display: 'inline-block',
        textAlign: 'left',
        overflowWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
    },
    messageWrapper: {
        textAlign: 'left',
    },
    ownMessage: {
        textAlign: 'right',
        "& > div": {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    chatBar: {
        top: 'auto',
        bottom: 0,
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
}));

const Chat = (props) => {
    const classes = useStyles();
    const { jobId, employee } = props;
    const me = useSelector(state => state.user.userData);
    const [messages, setMessages] = useState([]);
    const [msg, setMsg] = useState("");
    const [chatMeta, setChatMeta] = useState({});

    const interestedUser = useSelector(state => {
        return ( me.type === 'employer' ? state.jobs.interestedUsers[jobId][employee] : state.jobs.jobInterest[jobId]);
    })

    useEffect(() => {
        if ( !interestedUser || !interestedUser.chat ) return;
        let chat = interestedUser.chat;
        setChatMeta(chat);
        handleSetMessages(chat.messages);
        if( !chat[me.uid] || chat.count > chat[me.uid] ) {
            firebase.firestore().doc(`jobs/${jobId}/users/${employee}`).set({
                chat: {
                    [`${me.uid}`]: chat.count
                }
            }, {merge: true})
        }
    }, [interestedUser, employee, jobId, me.uid]);

    const handleSetMessages = (msgs, scroll = true) => {
        msgs = msgs.sort((a, b) => a.timestamp - b.timestamp);
        setMessages(msgs);
        if (scroll) {
            // handleScrollToBottom()
        }
    }

    const handleSendMessage = () => {
        setMsg(msg.trim())
        if (msg.length < 1) {
            return;
        }
        let doc = {
            message: msg.trim(),
            from: me.uid
        }
        let tmp = {
            messages: [],
            ...chatMeta
        }

        tmp.messages.push(doc);
        firebase.firestore().doc(`jobs/${jobId}/users/${employee}`).set({
            chat: {
                count: tmp.messages.length,
                messages: [
                    ...tmp.messages
                ]
            }
        }, {merge: true})
        setMsg("");
        tracker.logEvent("Chat message sent")
    }

    return (
        <div
            style={{
                height: '100%',
                background: '#fff',
            }}
        >
            <div className={classes.messagesWrapper} >
                <div className={classes.offset} />
                {messages.sort((a, b) => a.timestamp - b.timestamp).map((message, key) => (
                        <div
                            key={key}
                            className={clsx(classes.messageWrapper, (message.from === me.uid) ? classes.ownMessage : '')}
                        >
                            <div
                                className={classes.message}
                            >
                                {message.message}
                            </div>
                        </div>
                    )
                )}
                <div className={classes.offset} />
                <div className={classes.offset} />
                <AlwaysScrollToBottom />
            </div>
            <AppBar
                className={classes.chatBar}
                color="default"
            >
                <Toolbar>
                    <FormControl className={clsx(classes.margin, classes.textField)}>
                        <Input
                            autoFocus
                            multiline
                            rowsMax={3}
                            id="chat-message-input"
                            value={msg}
                            onChange={(e) => setMsg(e.target.value)}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    if (!e.shiftKey) {
                                        handleSendMessage();
                                    } else {
                                        setMsg(msg + "\n");
                                    }
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="send message"
                                        onClick={handleSendMessage}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Toolbar>
            </AppBar>
            <div className={classes.offset} />
        </div>
    );
}
const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };
  

export default Chat;