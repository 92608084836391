import { AppBar, Box, IconButton, LinearProgress, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/ungcash_white.png';
import EmployeeNavBar from '../../Components/Employee/NavBar';
import EmployerNavBar from '../../Components/Employer/NavBar';
import ProfilePicture from '../../Components/Users/ProfilePicture';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    offset: theme.mixins.toolbar,
}));


const TopBar = ({
    title = '',
    offset = true,
    progress = false,
    backButton = false,
    backPath = false
}) => {
    // const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const user = useSelector(state => state.user.userData);

    const handleOpenDrawer = () => {
        setDrawerOpen(true);
    }
    const handleCloseDrawer = () => {
        setDrawerOpen(false)
    }

    const handleBack = () => {
        if (backPath) {
            history.push(backPath);
        } else {
            history.goBack()
        }
    }

    return (
        <>
            <AppBar position="fixed" color="primary">
                <Toolbar>
                    {backButton ?
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="back" onClick={handleBack}>
                            <BackIcon />
                        </IconButton>
                        :
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleOpenDrawer}>
                            <MenuIcon />
                        </IconButton>
                    }
                    <Box className={classes.title} style={{
                        textAlign: 'center',
                        paddingTop: 10,
                    }}>
                        <NavLink to="/">
                            <img src={logo} alt="UngCash" style={{ width: 120 }} />
                        </NavLink>
                    </Box>
                    {/* <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography> */}

                    {/* <Button
                        color="inherit"
                        onClick={() => dispatch(logOut())}
                    >
                        Logg ut
                </Button> */}
                    <NavLink
                        to={`/profile`}
                        style={{ textDecoration: 'none' }}
                    >
                        <ProfilePicture
                            size={40}
                            allowEdit={false}
                        />
                    </NavLink>
                </Toolbar>
            </AppBar>
            {offset && <><div className={classes.offset} /><div className={classes.offset} /></>}
            {progress && <LinearProgress variant="determinate" value={progress} />}
            {user.type === 'employee' && <EmployeeNavBar open={drawerOpen} onClose={handleCloseDrawer} onOpen={handleOpenDrawer} />}
            {user.type === 'employer' && <EmployerNavBar open={drawerOpen} onClose={handleCloseDrawer} onOpen={handleOpenDrawer} />}
        </>
    );
}


export default TopBar;