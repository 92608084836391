import { Badge, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import formatDate from '../../Helpers/formatDate';
import { watchInterestedUsers } from '../../redux/jobs';
import AssignJobButton from '../Employer/AssignJobButton';
import ProfilePicture from '../Users/ProfilePicture';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#fff'
    },
    inline: {
        display: 'inline'
    }
}));

const Users = (props) => {
    const classes = useStyles();
    const { id = false } = useParams();
    const dispatch = useDispatch();
    const me = useSelector(state => state.user.userData);

    // const [modalUid, setModalUid] = useState( false );

    var interestedUsers = useSelector(state => state.jobs.interestedUsers);
    if (interestedUsers[id]) {
        interestedUsers = interestedUsers[id]
    } else {
        interestedUsers = {};
    }

    useEffect(() => {
        if (!id) return;
        dispatch(
            watchInterestedUsers(id)
        )
    }, [id, dispatch])

    // const handleModalOpen = (uid) => {
    //     setModalUid(uid);
    // }
    // const handleModalClose = () => {
    //     setModalUid(false);
    // }

    if (Object.keys(interestedUsers).length === 0) {
        return (
            <List className={classes.root}>
                <ListItem>
                    <ListItemText
                        primary="Venter på søknader..."
                    />
                </ListItem>
            </List>
        )
    }
    // const getAge = (birthDate) => {
    //     var today = new Date();
    //     var age = today.getFullYear() - birthDate.getFullYear();
    //     var m = today.getMonth() - birthDate.getMonth();
    //     if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //         age--;
    //     }
    //     return age;
    // }


    return (
        // <Grid className={classes.root} container spacing={4}>
        <List className={classes.root}>
            {Object.keys(interestedUsers).map(key => {
                let user = interestedUsers[key];

                let lastView = formatDate(user.viewed_at.toDate());
                let unread = 0;
                if (
                    user.chat
                    && user.chat.count
                    && user.chat.count > 0
                    && (
                        !user.chat[me.uid]
                        || user.chat.count > user.chat[me.uid]
                    )
                ) {
                    unread = (user.chat.count - (user.chat[me.uid] || 0));
                }

                return (
                    <>
                        <ListItem key={key} button alignItems="flex-start" component={NavLink} to={`/${id}/chat/${key}`}>
                            <ListItemAvatar>
                                <ProfilePicture uid={user.id} size={40} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<>{user.user.displayName} <Badge badgeContent={unread} max={9} color="primary" style={{ marginLeft: 7 }}><ChatIcon fontSize="small" /></Badge></>}
                                secondary={`${user.user.postArea || ''} | Visninger: ${user.views} | Sist innom: ${lastView}`}
                            />
                            <ListItemSecondaryAction>
                                <AssignJobButton id={id} uid={user.id} style={{ marginLeft: 'auto', marginBottom: 5 }} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </>
                )
            })}
        </List>
    );
}

export default Users;
