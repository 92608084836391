import { Typography } from "@material-ui/core";

const Terms = {
    version: 1.1,
    type: 'employer',
    content: (
        <>
            <Typography gutterBottom variant="h2">
                BRUKERVILKÅR TESTPERIODE
            </Typography>
            <Typography gutterBottom variant="h4">
                UngCash er fra 6. juni og frem til lansering i en testfase. Under testperioden av ungcash as sin applikasjon ungcash.no vil det være spesielle vilkår ved bruk av app. og tjenester formidlet.
            </Typography>
            <Typography gutterBottom variant="body1">
                UngCash.no er i en fase med utprøving og feil kan oppstå.
            </Typography>
            <ul>
                <li>
                    <Typography gutterBottom variant="body1">
                        UngCash har ingen juridiske eller økonomiske ansvar for eventuelle materielle eller skader på person. Det er kjøpers/oppdragsgivers egen forsikring som er gjeldene om det oppstår skader på eiendeler i forbindelse med Ungdommens arbeid.
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom variant="body1">
                        UngCash fraskriver seg i testperioden ansvar for økonomiske tap som oppstår mellom ungdom og oppdragsgiver.
                    </Typography>
                </li>
            </ul>
            <Typography gutterBottom variant="body1">
                <strong>
                    Kjøper og Ungdom bekrefter ved å akseptere denne avtalen:
                </strong>
            </Typography>
            <ol>
                <li>
                    <Typography gutterBottom variant="body1">
                        Godta meldinger fra UngCash.no, sms, push notice eller lignende
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom variant="body1">
                        Aksepterer å bli kontakt fra UngCash vedr brukeropplevelsen i testperioden.
                    </Typography>
                </li>
            </ol>
            <Typography gutterBottom variant="body1">
                Alle henvendelser rettes til <a href="mailto:post@ungcash.no">post@ungcash.no</a>.
            </Typography>
            <Typography gutterBottom variant="body1">
                UngCash drives av
            </Typography>
            <Typography gutterBottom variant="body1">
                UngCash AS (org.nr. 925265659)<br />
                Registrert adresse Losveien 15, 3150 Tolvsrød<br />
                Kontakt: <a href="mailto:post@ungcash.no">post@ungcash.no</a><br />
            </Typography>
        </>
    )
}

export default Terms