import { Box, Grid } from '@material-ui/core';
import React from 'react';

const BottomBar = ({children}) => {
    return ( 
        <Box
            style={{
                // position: 'fixed',
                // bottom: 0,
                // left: 0,
                // width: '100vw',
                // background: '#fff',
                // padding: 10,
                // boxShadow: '0 0 10px rgba(0,0,0,0.2)'
                marginBottom: 20
            }}
        >
            <Grid container spacing={3}>
                {children}
            </Grid>
        </Box>
                    
     );
}
 
export default BottomBar;