import { Button, Grid, TextField } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import firebase from '../../../config/firebase';
import tracker from '../../../Helpers/tracker';
import CenterFrame from '../../../Views/CenterFrame';
import Loader from '../../../Views/Loader';
import Logo from '../../../Views/Logo';
import ProfilePicture from '../ProfilePicture';

const ContactInfo = ({user, saveText = 'Lagre', redirectAfterSave = false}) => {

    const [loader, setLoader] = useState(false);
    const history = useHistory();
    
    const [userInfo, setUserInfo] = useState({ 
        firstName: user.firstName || "", 
        lastName: user.lastName || "", 
        displayName: user.displayName || "",
        address: user.address || "",
        postCode: user.postCode || "",
        postArea: user.postArea || "",
        birthDate: user.birthDate || "",
     })
    const [errors, setErrors] = useState({});
    
    const handleInput = (e) => {
        let tmp = { ...userInfo };
        tmp[e.target.name] = e.target.value;
        tmp.displayName = `${tmp.firstName}`;
        setUserInfo( tmp )
    }

    const handleDateChange = (e) => {
        let tmp = { ...userInfo };
        tmp.birthDate = e;
        setUserInfo( tmp )
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        let errs = {
            firstName: false,
            lastName: false,
            address: false,
            postCode: false,
            postArea: false,
            birthDate: false,
        }

        if(!userInfo.firstName || userInfo.firstName.length < 1 ) {
            errs.firstName = 'Feltet er påkrevd';
        }
        if(!userInfo.lastName || userInfo.lastName.length < 1 ) {
            errs.lastName = 'Feltet er påkrevd';
        }
        if(!userInfo.address || userInfo.address.length < 1 ) {
            errs.address = 'Feltet er påkrevd';
        }
        if(!userInfo.postCode || userInfo.postCode.length < 1 ) {
            errs.postCode = 'Feltet er påkrevd';
        }
        if(!userInfo.postArea || userInfo.postArea.length < 1 ) {
            errs.postArea = 'Feltet er påkrevd';
        }
        if(!userInfo.birthDate || userInfo.birthDate.length < 1 ) {
            errs.birthDate = 'Feltet er påkrevd';
        }
        setErrors(errs);

        if( Object.keys(errs).some(e => errs[e]) ) {
            setLoader(false);
            tracker.logEvent('Signup Contact - Info - error', { ...errs, userType: (userInfo.type || 'unset' ) })
            return false;
        }

        firebase.firestore().doc(`users/${user.uid}`).update(userInfo).then(() => {
            tracker.logEvent('Signup Contact - Info - success')
            if( redirectAfterSave ) {
                history.push(redirectAfterSave);
            }
        });
        setLoader(false);
    }

    useEffect(() => {
        tracker.logEvent('Signup Contact - Info - loaded')
    }, [])

    if (loader) return <Loader />

    return ( 
        <CenterFrame>
            <Logo
                style={{
                    marginBottom: 25
                }}
            />

            <ProfilePicture
                uid={user.uid}
                allowEdit={true}
            />

            <form onSubmit={handleSubmit} autoComplete="off">
                <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete='off'
                    name="firstName"
                    placeholder={`Fornavn`}
                    label={`Fornavn`}
                    value={userInfo.firstName}
                    onChange={handleInput}
                    error={!!(errors.firstName)}
                    helperText={errors.firstName || ''}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete='off'
                    name="lastName"
                    placeholder={`Etternavn`}
                    label={`Etternavn`}
                    value={userInfo.lastName}
                    onChange={handleInput}
                    error={!!(errors.lastName)}
                    helperText={errors.lastName || ''}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete='off'
                    name="address"
                    placeholder={`Adresse`}
                    label={`Adresse`}
                    value={userInfo.address}
                    onChange={handleInput}
                    error={!!(errors.address)}
                    helperText={errors.address || ''}
                />
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            autoComplete='off'
                            name="postCode"
                            placeholder={`Postnr.`}
                            label={`Postnr.`}
                            value={userInfo.postCode}
                            onChange={handleInput}
                            error={!!(errors.postCode)}
                            helperText={errors.postCode || ''}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            autoComplete='off'
                            name="postArea"
                            placeholder={`Poststed`}
                            label={`Poststed`}
                            value={userInfo.postArea}
                            onChange={handleInput}
                            error={!!(errors.postArea)}
                            helperText={errors.postArea || ''}
                        />
                    </Grid>
                </Grid>
                {/* <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete='off'
                    type="date"
                    name="birthDate"
                    label={`Fødselsdato`}
                    value={userInfo.birthDate}
                    onChange={handleInput}
                    error={!!(errors.birthDate)}
                    helperText={errors.birthDate || ''}
                    InputLabelProps={{
                        shrink: true,
                    }}
                /> */}
                <DatePicker
                    disableFuture
                    inputVariant="outlined"
                    fullWidth
                    margin="normal"
                    openTo="year"
                    format="dd.MM.yyyy"
                    label={`Fødselsdato`}
                    name="birthDate"
                    views={["year", "month", "date"]}
                    value={typeof userInfo.birthDate.toDate === 'function' ? userInfo.birthDate.toDate() : userInfo.birthDate}
                    onChange={handleDateChange}
                />

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    style={{
                        marginTop: 10
                    }}
                >
                    {saveText}
                </Button>
            </form>
        </CenterFrame>
     );
}
 
export default ContactInfo;