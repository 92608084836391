import { List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import DrawerWrapper from '../../Views/Common/DrawerWrapper';

const EmployeeNavBar = ({ open, onClose, onOpen }) => {
    return (
        <DrawerWrapper open={open} onClose={onClose} onOpen={onOpen}>
            <List>
                <ListItem button key="first" component={NavLink} to="/">
                    <ListItemText>Jobber</ListItemText>
                </ListItem>
            </List>
        </DrawerWrapper>
    )
}

export default EmployeeNavBar;