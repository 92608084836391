import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { default as IOSPrompt } from 'react-ios-pwa-prompt';
import usePWA from 'react-pwa-install-prompt';

const PWAPrompt = () => {
    const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA();
    const [showModal, setShowModal] = useState(true);

    const text = {
        copyTitle: `Legg på hjemskjerm`,
        copyBody: `Denne nettsiden har app-funksjonalitet. Legg den på hjemskjermen på mobilen for å få enklere tilgang!`,
        copyShareButtonLabel: `1) Trykk på 'del' knappen`,
        copyAddHomeButtonLabel: `2) Trykk på 'Legg på hjemskjerm'`,
        copyClosePrompt: 'Avbryt',
    }


    if( isStandalone || !showModal ) return null;

    const onClickInstall = async () => {
        const didInstall = await promptInstall()
        if (didInstall) {
            setShowModal(false)
        }
    }

    if (isInstallPromptSupported && isStandalone) {
        return (
            <Dialog open={showModal}>
                <DialogTitle>{text.copyTitle}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{text.copyBody}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => setShowModal(false)}>
                        {text.copyClosePrompt}
                    </Button>
                    <Button color="primary" onClick={onClickInstall}>
                        {text.copyTitle}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return ( 
        <IOSPrompt
            debug={false}
            timesToShow={3}
            {...text}
        />
    );
}
    
export default PWAPrompt;