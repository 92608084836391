// import React from 'react';
// import BottomBar from '../../../Views/Common/BottomBar';

const Archived = ({job}) => {
    // const { id = false, action = 'view' } = useParams();

    return null
    // (
    //     <BottomBar>
    //         EmployeeActionArchived
    //     </BottomBar>
    // )
}

export default Archived;