import { Box, CircularProgress } from '@material-ui/core';
import CenterFrame from './CenterFrame';
import Logo from './Logo';

const Loader = () => {

    return (
        <CenterFrame>
            <Logo />
            <Box>
                <CircularProgress color="primary" />
            </Box>
        </CenterFrame>
    );
}

export default Loader;