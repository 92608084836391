import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePicture from '../../Components/Users/ProfilePicture';
import { getPublicData } from '../../redux/user';

const UserWidget = ({uid}) => {
    const user = useSelector(state => state.user.public[uid])
    const dispatch = useDispatch();
    
    useEffect(() => {
        if( !uid ) return; 
        if( !user ) {
            dispatch(
                getPublicData( uid )
            )
        }
    }, [uid, user, dispatch])
    
    if( !uid || !user ) return null;
    return (
        <ProfilePicture uid={uid} />
    )
}

export default UserWidget;