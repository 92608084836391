import { makeStyles, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPublicData } from '../../redux/user';

const useStyles = makeStyles((theme) => ({
    rating: {
        color: '#FFF066',
        fontSize: 30,
        padding: 0
    }
}));

const Rating = ({uid = false}) => {
    const user = useSelector(state => state.user.public[uid]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [feedback, setFeedback] = useState(false);

    useEffect(() => {
        if( !uid ) return; 
        if( !user ) {
            dispatch(
                getPublicData( uid )
            )
        }
        setFeedback(user.feedback || false);
    }, [uid, user, dispatch])
    
    if( !uid || !user ) return null;
    
    let stars = [
        <StarBorderIcon className={classes.rating} />,
        <StarBorderIcon className={classes.rating} />,
        <StarBorderIcon className={classes.rating} />,
        <StarBorderIcon className={classes.rating} />,
        <StarBorderIcon className={classes.rating} />
    ];

    if( !feedback || !feedback.stars ) return null;

    var wholeStars = Math.floor(feedback.stars);
    
    var halfStar = (wholeStars < feedback.stars);

    for (var i = 1; i <= wholeStars; i++) {
        stars[i-1] = <StarIcon className={classes.rating} />
    }
    if(halfStar) {
        stars[i-1] = <StarHalfIcon className={classes.rating} />
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start'
        }}>
            <div>
            {stars.map((i,k) => (<span key={k}>{i}</span>))}
            </div>
            <div>

            <Typography variant="body2">
                {feedback.num && `(${feedback.num})`}
            </Typography>
            </div>
        </div>
    )
}

export default Rating;