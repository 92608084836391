import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { NavLink } from 'react-router-dom';

const AddJobFab = () => {
    return (
        <Fab color="primary" aria-label="add"
            component={NavLink}
            to="/create/"
            style={{
                position: 'fixed',
                bottom: 10,
                right: 10,
                zIndex: 9999
            }}
        >
            <AddIcon />
        </Fab>
    )
}

export default AddJobFab;