import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import tracker from '../../Helpers/tracker';

const RejectDialog = ({dialogOpen, handleDialogClose}) => {
    const uid = useSelector(state => state.user.user.uid );
    
    useEffect(() => {
        tracker.logEvent('Reject dialog opened', { userid: uid })
    }, [uid])

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>{`Tvist`}</DialogTitle>
            <DialogContent>
                    <Typography variant="body1" gutterBottom>Ved uenighet om kravet skal UngCash AS konsulteres som mekler. Vennligst ta kontakt med oss hvis uenigheter.</Typography>
                    <Typography variant="body1">
                        <strong>Kontaktinformasjon</strong><br />
                        Telefon: <a href="tel:+4793498200">934 98 200</a><br />
                        E-post: <a href="mailto:tvist@ungcash.no">tvist@ungcash.no</a><br />

                    </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="secondary">
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RejectDialog;