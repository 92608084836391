import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import ListItem from '../../Views/Jobs/ListItem';

const ListSection = ({title, items}) => {
    items = items.filter(function (el) {
        return !!el;
    });

    if( items.length === 0 ) {
        return null
    }

    return (
        <Container style={{ marginTop: 30 }}>
            {!!title && (<Typography variant="h4">{title}</Typography>)}
            <Grid container spacing={3}>
                {items.map((i,k) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={k}>
                        <ListItem item={i} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default ListSection;