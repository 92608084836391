import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import firebase from '../../config/firebase';
import CheckIcon from '@material-ui/icons/Check';
import tracker from '../../Helpers/tracker';

const AssignJobButton = ({id, uid, style = {} }) => {
    const job = useSelector(state => state.jobs.jobs[id]);
    const me = useSelector(state => state.user.userData);
    
    if( job.owner !== me.uid ) return null;
    
    const handleAssignJob = () => {
        firebase.firestore().doc(`jobs/${id}`).update({
            assigned_to: uid,
            status: 'assigned'
        })
        tracker.logEvent('Job assigned', { jobId: id })
    }
    if( job.assigned_to ) {
        return (
            <CheckIcon />
        )
    }
    return ( 
        // { job.status === 'published' && (
            <Button 
                color="primary"
                variant="contained"
                onClick={handleAssignJob}
                style={style}
            >
                Velg
            </Button>
        // )}
     );
}
 
export default AssignJobButton;