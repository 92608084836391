import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePicture from '../../Components/Users/ProfilePicture';
import { getPublicData } from '../../redux/user';
import Rating from '../Common/Rating';

const useStyles = makeStyles(theme => ({
    presentationWrapper: {
        display: 'flex',
        flexDirection: 'row'
    },
    profilePicture: {
        width: 70
    }
}));

const UserPresentation = ({ uid = false }) => {
    const user = useSelector(state => state.user.public[uid])
    const dispatch = useDispatch();
    const classes = useStyles();
    
    useEffect(() => {
        if( !uid ) return; 
        if( !user ) {
            dispatch(
                getPublicData( uid )
            )
        }
    }, [uid, user, dispatch])
    
    if( !uid || !user ) return null;
    

    return ( 
        <Box className={classes.presentationWrapper}>
            <Box className={classes.profilePicture}>
                <ProfilePicture uid={uid} size={40} allowEdit={false} />
            </Box>
            <Box>
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{user.displayName}</Typography>
                <Rating feedback={uid} />
            </Box>
        </Box>
     );
}
 
export default UserPresentation;