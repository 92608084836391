import { Avatar, Box, CircularProgress, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadProfilePicture from '../../Helpers/UploadProfilePicture';
import { getPublicData } from '../../redux/user';


const useStyles = makeStyles((theme) => ({
    profileFallback: {
        color: theme.palette.getContrastText(green[800]),
        backgroundColor: green[500],
        textDecoration: 'none !important'
    }
}));

const ProfilePicture = (props) => {
    const classes = useStyles();
    const { 
        size = 150, 
        allowEdit = false,
    } = props;
    
    const [photoURL, setPhotoURL] = useState(null);
    // const [dbPhotoURL, setDBPhotoURL] = useState(null);
    const [uid, setUid] = useState(props.uid);

    const userData = useSelector(state => state.user.userData);
    const [isUploading, setIsUploading] = useState(false);
    const publicData = useSelector(state => state.user.public[uid]);

    const dispatch = useDispatch();
    
    useEffect(() => {
        if( !uid ) {
            if( userData.uid ) {
                setUid(userData.uid);
            }
            return;
        }
        if( uid === userData.uid ) {
            if( photoURL === userData.photoURL || !userData.photoURL ) {
                return 
            }
            setPhotoURL(userData.photoURL);
            // setDBPhotoURL(userData.photoURL);
            return;
        }
        if( publicData ) {
            if( photoURL === publicData.photoURL || !publicData.photoURL ) {
                return 
            }
            setPhotoURL(publicData.photoURL);
            // setDBPhotoURL(publicData.photoURL);
        }
    }, [publicData, uid, userData, photoURL])

    useEffect(() => {
        if( !uid ||  uid === userData.uid ) return
        dispatch(
            getPublicData(
                uid
            )
        );
    }, )

    
    
    if( !uid ) {
        return null;
    }

    let displayName;
    if( uid === userData.uid ) {
        displayName = (userData.displayName || 'Profilbilde');
    } else if ( publicData ) {
        displayName = (publicData.displayName || 'Profilbilde');
    }
    return (
        <>
            <Box
                style={{
                    width: size,
                    height: size,
                    borderRadius: '100%',
                    margin: '0 auto',
                    position: 'relative',
                }}
            >
                <Avatar
                    style={{
                        width: size,
                        height: size,
                    }}
                    className={classes.profileFallback}
                    alt={displayName || "Profilbilde"}
                    src={photoURL || " "}
                    // src="/asdf"
                    children={(isUploading ?
                        <CircularProgress style={{color: '#fff'}} />
                        : null
                    )}
                />
                
                { allowEdit && 
                    <UploadProfilePicture uid={uid} setIsUploading={setIsUploading} />
                }
            </Box>
        </>
    );
}

export default ProfilePicture;