// 4r from '../../../Views/Common/BottomBar';

const Archived = ({job}) => {
    // const { id = false, action = 'view' } = useParams();

    return null
    //     <BottomBar>
    //         EmployerActionArchived
    //     </BottomBar>
    // )
}

export default Archived