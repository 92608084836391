import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logOut } from '../../redux/user';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
      },
}));

const DrawerWrapper = ({ children, open, onClose, onOpen }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <SwipeableDrawer anchor="left" open={open || false} onClose={onClose} onOpen={onOpen}>
            <div className={classes.drawerHeader}>
                <IconButton onClick={onClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <div className={classes.list}>

                {children}
                <Divider />
                <List>
                    <ListItem button key="profile" to="/profile" component={NavLink}>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText>Profil</ListItemText>
                    </ListItem>
                    <ListItem button key="logout" onClick={() => { dispatch(logOut()) }}>
                        <ListItemIcon>
                            <LockIcon />
                        </ListItemIcon>
                        <ListItemText>Logg ut</ListItemText>
                    </ListItem>
                </List>
            </div>
        </SwipeableDrawer>
    )
}

export default DrawerWrapper;