import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router';
import tracker from '../../Helpers/tracker';
import { watchJob } from '../../redux/jobs';
import TopBar from '../../Views/Common/TopBar';
import Loader from '../../Views/Loader';
import ChatModal from '../Employer/ChatModal';
import ViewJob from '../Jobs/ViewJob';
import EmployeeActions from './Actions';


const SingleJob = (props) => {
    const { id = false, action = 'view' } = useParams();
    const [trackedID, setTrackedID] = useState(null);
    const job = useSelector(state => state.jobs.jobs[id])
    const user = useSelector(state => state.user.userData);
    const dispatch = useDispatch();

    useEffect(() => {
        if ( !job || id === trackedID ) return;
        tracker.logEvent('Show job', { jobId: id })
        setTrackedID(id);
    }, [id, job, trackedID, setTrackedID])
    
    if( job === undefined) {
        dispatch(watchJob(id));
        return ( <Loader /> )
    }

    const backPath = (action === 'view' ? `/` : `/${id}/`);
    const backButton = (!!backPath);

    return ( 
        <>
            <TopBar backPath={backPath} backButton={backButton} />
            <Container 
                maxWidth="sm"
                style={{
                    marginBottom: 100
                }}
                >
                {user.type === 'employee' && job && action !== 'chat' &&
                    <EmployeeActions job={job} />
                }
                <Switch>
                    <Route path="/:id/chat/:uid">
                        <ChatModal />
                    </Route>
                    <Route>
                        <ViewJob />
                    </Route>
                </Switch>
            </Container>
        </>
    );
}
 
export default SingleJob;