import { Grid, Modal } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React from 'react';
import { NavLink } from 'react-router-dom';
import CenterFrame from '../CenterFrame';
import Logo from '../Logo';


const FullscreenJobView = ({ children }) => {
    return (
        <Modal
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0,0,0,0.85)',

                }
            }}
            open={true}
        >
            <CenterFrame>
                <Grid container spacing={4}>
                    <Grid item xs={2}>
                        <NavLink to="/" style={{}}>
                            <ChevronLeftIcon style={{ color: '#fff', fontSize: 48 }} />
                        </NavLink>
                    </Grid>
                    <Grid item xs={8} style={{ position: 'relative' }}>
                        <NavLink to="/">
                            <Logo variant="white" style={{ marginBottom: 10 }} />
                        </NavLink>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    {children || ''}
                </Grid>
            </CenterFrame>
        </Modal>
    );
}

export default FullscreenJobView;