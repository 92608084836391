import { Container, FormControl, Grid, InputLabel, Select, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import categories from '../../config/JobCategories';
import { addFilter, setFilters, watchPublishedJobs } from '../../redux/jobs';
import TopBar from '../../Views/Common/TopBar';
import ListSection from '../../Views/Jobs/ListSection';
import MyJobs from './MyJobs';

const EmployeeJobList = () => {
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const jobs = useSelector(state => state.jobs.jobs);
    const filteredJobs = useSelector(state => state.jobs.filteredJobs);
    const filters = useSelector(state => state.jobs.filters)

    useEffect(() => {
        dispatch(
            watchPublishedJobs()
        )
    }, [dispatch])

    useEffect(() => {
        if (loaded) return;
        dispatch(
            setFilters({
                'category': ''
            })
        )
        setLoaded(true);
    }, [setLoaded, loaded, dispatch])

    const items = filteredJobs.map((i) => jobs[i])

    return (
        <>
            <TopBar title={`Jobber`} />

            <Container maxWidth="sm" style={{ marginTop: 30 }}>

                <MyJobs status={['assigned', 'completed']} title={`Dine jobber`} />

                <MyJobs status={['published']} title={`Søknader`} />
                {/* <MyJobs status={['paid']} title={`Fullførte jobber`} /> */}

                <Grid container>
                    <Grid item xs={12}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                        // disabled={true}

                        >
                            <InputLabel id="category-label" shrink={true}>Kategori</InputLabel>
                            <Select
                                native
                                labelId="category-label"
                                id="category"
                                value={filters.category}
                                onChange={(e) => dispatch(addFilter({ 'category': e.target.value }))}
                                name="category"
                            >
                                <option aria-label="Vis alle" value="">Vis alle</option>
                                {Object.keys(categories).map((key) => {
                                    let obj = categories[key];
                                    return (<option value={obj.key} key={obj.key}>{obj.label}</option>)
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
            {items.length > 0 ?
                <ListSection items={items} />
                :
                <Container maxWidth="sm" style={{ marginTop: 30 }}>
                    <Typography variant="body1">
                        Ingen jobber funnet...
                    </Typography>
                </Container>
            }
        </>
    );
}

export default EmployeeJobList;