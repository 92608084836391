import React from 'react';
import { Route, Switch } from 'react-router';
import CreateJob from '../Jobs/CreateJob';
import JobList from './JobList';
import SingleJob from './SingleJob';


const Employer = () => {

    return ( 
        <>
            <Switch>
                <Route path="/create/" component={CreateJob} />
                <Route path="/:id/:action" component={SingleJob} />
                <Route path="/:id" component={SingleJob} />
                <Route component={JobList} />
            </Switch>
        </>
    );
}
 
export default Employer;