import { Button, CircularProgress, Grid, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import firebase from '../../config/firebase';
import tracker from '../../Helpers/tracker';
import { watchJob } from '../../redux/jobs.js';
import CenterFrame from '../../Views/CenterFrame';
import Loader from '../../Views/Loader.js';
import terms from '../JobTerms.js';


const PublishJob = (props) => {
    const { id = false } = useParams();
    const user = useSelector(state => state.user.userData);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const job = useSelector(state => state.jobs.jobs[id]);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (loaded) return false;

        dispatch(
            watchJob(id)
        );
        setLoaded(true);

        tracker.logEvent('Publish job - loaded')

    }, [loaded, dispatch, id]);


    terms['date'] = firebase.firestore.FieldValue.serverTimestamp();

    const handleReject = () => {
        alert('Du må godta for å publisere jobben.');
        tracker.logEvent('Publish job - rejected')
        history.push(`/${id}/edit`)
    }
    const handleAccept = () => {
        setLoading(true);
        tracker.logEvent('Publish job - accepted')
        
        firebase.firestore().doc(`jobs/${id}`).update({
            status: 'published',
            terms: {
                version: terms.version,
                date: terms.date,
                user: user.uid
            }
        });
    }
    if (!loaded) return <Loader />
    if( job && job.status !== 'draft' && job.terms && job.terms.version >= terms.version ) {
        history.push(`/${id}`);
    }

    return ( 
        <Modal open={true} onClose={handleReject}>
            <CenterFrame style={{backgroundColor: '#fff'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}
                        style={{
                            height: '60vh',
                            background: '#eee',
                            border: '1px solid #ddd',
                            padding: 10,
                            borderRadius: 5,
                            overflow: 'auto',
                            textAlign: 'left'
                        }}
                    >
                        {terms.content}
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleReject}
                            >
                            Avvis
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleAccept}
                            >
                            {loading ? <CircularProgress color="inherit" size={24} /> : `Godta`}
                        </Button>
                    </Grid>
                </Grid>
            </CenterFrame>
        </Modal>
     );
}
 
export default PublishJob;