import React, { useRef } from 'react';
import firebase from '../config/firebase';
import CameraIcon from '@material-ui/icons/CameraAlt';
import { useSelector } from 'react-redux';
import { Fab } from '@material-ui/core';

const UploadProfilePicture = (props) => {
    const { 
        uid,
        setIsUploading = () => {}
    } = props;
    const uploadRef = useRef();
    const userData = useSelector(state => state.user.userData);

    if( !uid ) return null

    const handleUpload = (e) => {
        let file = e.target.files[0]
        if (file === '') {
            console.error(`not an image, the image file is a ${typeof (file)}`)
        }

        var uploadTask = firebase.storage().ref(`/profile_pictures/${userData.uid}/${file.name}`).put(file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // logger('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        // logger('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        // logger('Upload is running');
                        setIsUploading(true);
                        break;
                    default:
                        break;
                }
            },
            (error) => {
                console.error('An error occured: ', error );
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    firebase.firestore().doc(`users/${userData.uid}`).update({
                        photoURL: downloadURL
                    })
                    setIsUploading(false);
                    // logger('File available at', downloadURL);
                });
            }
        );
    }

    return ( 
        <>
            <input
                accept='image/x-png, image/jpeg'
                id='myfileinput'
                capture="camera"
                className='inputfile'
                type='file'
                onChange={handleUpload}
                style={{ 
                    display: 'none'
                }}
                ref={uploadRef}
            />
            <Fab
                color="primary" 
                aria-label="upload" 
                size="small"
                style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0
                }}
                onClick={() => {
                    uploadRef.current.click()
                }}
            >
                <CameraIcon />
            </Fab>
        </>
    );
}
 
export default UploadProfilePicture;