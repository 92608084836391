import { Typography } from "@material-ui/core";

const Terms = {
    version: 1,
    type: 'post-job',
    content: (
        <>
            <Typography gutterBottom variant="h4" align="center">VILKÅR FOR UNGDOMMENS SIKKERHET</Typography>
            <Typography gutterBottom variant="body1">
                For ungdommens sikkerhet er det viktig du bekrefter følgende:
            </Typography>
            <Typography gutterBottom variant="body1">
                Jobben jeg ønsker utført krever <strong>INGEN farlige verktøy</strong> som krever spesiell opplæring. Ved usikkerhet om verktøy anses som farlig, anbefales foresattes godkjenning.
            </Typography>
            <Typography gutterBottom variant="body1">
                Eksempler på farlige verktøy:
                <ul>
                    <li>Motorsag</li>
                    <li>Gjerde sag</li>
                    <li>Sirkelsag</li>
                    <li>Spikerpistol</li>
                    <li>Boltepistol</li>
                    <li>Eksplosiver</li>
                    <li>Syrer som er spesielt etsende</li>
                </ul>
            </Typography>
            <Typography gutterBottom variant="body1">
                Jeg bekrefter at <strong>nødvendig verneutstyr</strong> presenteres arbeidstager ved oppdragets start.
            </Typography>
            <Typography gutterBottom variant="body1">
                Eksempler på verneutstyr:
                <ul>
                    <li>Hansker</li>
                    <li>Hørselvern</li>
                    <li>Vernebriller</li>
                    <li>Hjelm</li>
                </ul>
                Generelle vilkår for kjøper gjelder i tillegg til vilkår for kjøper.
            </Typography>
        </>
    )
}

export default Terms