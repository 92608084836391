import { Badge, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
// import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { markAsInteresting } from '../../../redux/jobs';

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        textAlign: 'center'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));


const Published = ({job}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const me = useSelector(state => state.user.userData);
    const assignee = useSelector(state => (state.jobs.jobInterest[job.id] || false))
    // const history = useHistory();

    if( assignee && assignee.interested === true ) {

        let unread = 0;
        if( 
            assignee
            && assignee.chat 
            && assignee.chat.count
            && assignee.chat.count > 0
            && (
                !assignee.chat[me.uid] 
                || assignee.chat.count > assignee.chat[me.uid]
            )
        ) {
            unread = (assignee.chat.count-assignee.chat[me.uid]);
        }

        return (
            <Grid container spacing={3} style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>Venter tilbakemelding</Typography>
                    <Typography variant="body1" gutterBottom>Du har søkt på denne jobben og venter på tilbakemelding fra oppdragsgiver.</Typography>
                    <Typography variant="body1" gutterBottom>I mellomtiden kan du sende meldinger til oppdragsgiveren om det er noe du lurer på eller vil si for å øke sjansen for at akkurat du blir valgt.</Typography>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6} className={classes.actionButtons}>
                    <Badge badgeContent={unread} max={9} color="secondary" style={{ width: '100%'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            aria-label="open chat"
                            component={NavLink}
                            to={`/${job.id}/chat/${me.uid}`}
                            fullWidth
                        >
                                <ChatIcon className={classes.extendedIcon} />
                                Meldinger
                        </Button>
                    </Badge>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={3} style={{ marginBottom: 20 }}>
            <Grid item xs={6}
                className={classes.actionButtons}
            >
            </Grid>
            <Grid item xs={6}
                className={classes.actionButtons}
            >
                <Button
                    variant="contained"
                    color="primary"
                    aria-label="apply job"
                    fullWidth
                    onClick={() => {
                        dispatch(
                            markAsInteresting(job.id)
                        )
                    }}
                >
                    Søk jobb
                </Button>
            </Grid>
        </Grid>
    )
}

export default Published;