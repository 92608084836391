import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => createStyles({
        appWrapper: {
            paddingBottom: theme.spacing(20) + 'px !important',
        },
        background: {
            backgroundRepeat: "no-repeat",
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            position: 'relative',
            outline: 0
        },
        backdrop: {
            background: 'rgba(0,0,0,0.6)',
        },
        root: {
            flexGrow: 1,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // minHeight: "100vh",
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        userMenu: {
            position: 'absolute !important',
            top: theme.spacing(2) + 'px !important',
            right: theme.spacing(2) + 'px !important',
            opacity: 0.3,
            "&:hover": {
                opacity: 1
            }
        },
        card: {
            padding: theme.spacing(3, 2),
            display: "flex",
            width: "100%"
        },
        cardContent: {
            width: "100%"
        },
        logo: {
            maxWidth: 200
        },
        textCenter: {
            textAlign: 'center'
        },
        storeCategory: {
            textAlign: 'center',
            // color: '#fff'
        },
        storeCategoryText: {
            '& .MuiListItemText-primary': {
                fontSize: 36
            }
        },
        storeProduct: {
            backgroundColor: "rgba(0,0,0,0)",
            border: 'none',
            borderBottom: '1px ' + theme.palette.text.secondary + ' solid',
            color: theme.palette.text.secondary,
        },
        itemPrice: {
            float: 'right'
        },
        shopOrderModal: {
            width: '100vw',
            height: '100vh',
            boxSizing: 'border-box',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default
            // paddingTop: '3%',
        },
        addToCartWrapper: {
            // width: '100vw',
            height: '100vh',
            // backgroundColor: theme.palette.background.default
        },
        blurredBg: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            filter: 'blur(30px)',
            '-webkit-filter': 'blur(30px)',
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            zIndex: -1,
            opacity: 0.4
        },
        modalPaper: {
            maxHeight: '94vh',
            overflowY: 'auto',
            padding: theme.spacing(2)
        },
        navWrapper: {
            backgroundColor: "rgba(0,0,0,0.6)"
        },
        navLink: {
            ...theme.typography.button,
            textDecoration: 'none',
            display: 'block',
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            backgroundColor: theme.palette.primary.main + 'aa',
            color: theme.palette.primary.contrastText + ' !important',
            "&:hover": {
                backgroundColor: theme.palette.primary.main + 'ff',
            }
        },
        activeLink: {
            backgroundColor: theme.palette.primary.main + 'ff',
        },
        cartDrawer: {
            '& .MuiDrawer-paper': {
                width: '600px !important',
                maxWidth: '90% !important',
                minWidth: '40% !important',
                padding: 15
            }
        },
        cartFab: {
            position: 'fixed !important',
            bottom: theme.spacing(3) + 'px !important',
            right: theme.spacing(3) + 'px !important',
            zIndex: 1200
        },
        fullScreen: {
            width: '100%',
            height: '100%',
            background: '#fff',
            position: 'fixed',
            top: 0,
            left: 0,
        }
        // '@media (max-width: 1024px)': {
        //     cartDrawer: {
        //         '& .MuiDrawer-paper': {
        //             minWidth: '80% !important'
        //         }
        //     },
        // }
    }));

export default useStyles;
