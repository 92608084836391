const initialState = {
    listeners: {}
}

const helper = (state = initialState, action) => {
    let tmp;
    switch (action.type) {
        case '@helper/resetState':
            return initialState;

        case '@helper/setListener':
            tmp = { ...state.listeners };
            tmp[action.key] = action.listener;
            return { ...state, listeners: tmp }

        case '@helper/unsetListener':
            tmp = { ...state.listeners };
            delete tmp[action.key];
            return { ...state, listeners: tmp }

        default:
            return state;
    }
}

const unsetListener = (key) => ({
    type: '@helper/unsetListener',
    key
})

const setListener = (key, listener) => ({
    type: '@helper/setListener',
    key,
    listener
})

const killListener = (key) => ((dispatch, getState) => {
    if( getState().helper.listeners[key] && typeof getState().helper.listeners[key] === 'function') {
        getState().helper.listeners[key]();
        dispatch(
            unsetListener(key)
        );
    }
})

const doResetState = () => ({
    type: '@helper/resetState'
})

const resetState = () => ((dispatch, getState) => {
    Object.keys(getState().helper.listeners).map(i => {
        let tmp = getState().helper.listeners[i];
        if( typeof tmp === 'function') {
            tmp();
        }
        return i;
    })
    dispatch(
        doResetState()
    )
})

export default helper;
export { resetState, setListener, killListener };