import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import i18next from "i18next";
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";
import { Provider } from 'react-redux';
import App from './App';
import store from './redux';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import translation_en from "./translations/en/translation.json";
import translation_no from "./translations/no/translation.json";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'no',                              // language to use
  resources: {
    en: {
      translation: translation_en               // 'translation' is our custom namespace
    },
    no: {
      translation: translation_no
    },
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0F931C'
    },
    secondary: {
      main: '#34384B'
    }
  },
  typography: {
    h1: {
      fontSize: 36
    },
    h2: {
      fontSize: 30
    },
    h3: {
      fontSize: 24
    },
    h4: {
      fontSize: 20
    },
    h5: {
      fontSize: 18,
      fontWeight: 800
    }
  },
  
  overrides: {
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    },
    MuiButton: {
      // containedPrimary: {
      //   background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
      //   borderRadius: 3,
      //   border: 0,
      //   color: 'white',
      //   height: 48,
      //   padding: '0 30px',
      //   boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
      // },
      // containedSecondary: {
      //   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      //   borderRadius: 3,
      //   border: 0,
      //   color: 'white',
      //   height: 48,
      //   padding: '0 30px',
      //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      // },
      // outlinedPrimary: {
      //   borderRadius: 3,
      //   borderColor: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
      //   height: 48,
      //   padding: '0 30px',
      //   boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
      // },
      // outlinedSecondary: {
      //   borderRadius: 3,
      //   borderColor: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      //   height: 48,
      //   padding: '0 30px',
      //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      // }
    },
    MuiFab: {
      // primary: {
      //   background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
      //   color: 'white',
      // }
    }
  }
});

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();