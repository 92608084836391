import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import firebase from '../../config/firebase';
import tracker from '../../Helpers/tracker';

const GiveFeedback = ({ job, userId }) => {
    const [num, setNum] = useState(2);
    const [dialogOpen, setDialogOpen] = useState(false);
    const me = useSelector( state => state.user.userData );


    if( me.uid === userId ) return null;

    const handleHover = (id) => {
        setNum(id)
    }

    const handleDialogOpen = () => {
        setDialogOpen(true)
    }
    const handleDialogClose = () => {
        setDialogOpen(false)
    }
    const handleSubmit = (comment) => {
        firebase.firestore().doc(`users/${userId}/feedback/${job.id}`).set({
            jobId: job.id,
            from: me.uid,
            stars: num,
            comment
        }).then(() => {
            handleDialogClose();
        })
        tracker.logEvent('Feedback sent', { jobId: job.id })
        
    }

    return ( 
        <>
            <Typography variant="body2">Gi tilbakemelding</Typography>
            <Stars num={num} handleHover={handleHover} handleDialogOpen={handleDialogOpen} />
            <CommentDialog dialogOpen={dialogOpen} handleDialogClose={handleDialogClose} num={num} handleHover={handleHover} handleDialogOpen={handleDialogOpen} handleSubmit={handleSubmit} />
        </>
    );
}

const CommentDialog = ({dialogOpen, handleDialogClose, num, handleHover, handleDialogOpen, handleSubmit}) => {
    const [comment, setComment] = useState("");
    
    return (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>{`Gi tilbakemelding`}</DialogTitle>
            <DialogContent>
                    <Stars num={num} handleHover={handleHover} handleDialogOpen={handleDialogOpen} />
                <TextField
                    fullWidth
                    placeholder="Kommentar"
                    label="Kommentar (valgfritt)"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="secondary">
                    Avbryt
                </Button>
                <Button onClick={() => handleSubmit( comment )} color="secondary">
                    Send tilbakemelding
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const Stars = ({num, handleHover, handleDialogOpen}) => {
    return (
        <>
            <Star id={1} num={num} handleHover={handleHover} handleDialogOpen={handleDialogOpen} />
            <Star id={2} num={num} handleHover={handleHover} handleDialogOpen={handleDialogOpen} />
            <Star id={3} num={num} handleHover={handleHover} handleDialogOpen={handleDialogOpen} />
            <Star id={4} num={num} handleHover={handleHover} handleDialogOpen={handleDialogOpen} />
            <Star id={5} num={num} handleHover={handleHover} handleDialogOpen={handleDialogOpen} />
        </>
    )
}

const Star = ({ id, num, handleHover = () => {}, handleDialogOpen = () => {} }) => {
    if( id <= num ) {
        return <StarIcon onMouseEnter={() => handleHover(id)} onClick={handleDialogOpen} style={{ cursor: 'pointer' }} />
    }
    return <StarBorderIcon onMouseEnter={() => handleHover(id)} onClick={handleDialogOpen} style={{ cursor: 'pointer' }} />
}
 
export default GiveFeedback;