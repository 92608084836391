import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import EmployerTerms from './Terms/Employer';
import EmployeeTerms from './Terms/Employee';
import { useSelector } from 'react-redux';
import CenterFrame from '../../../Views/CenterFrame';
import firebase from '../../../config/firebase';
import tracker from '../../../Helpers/tracker';


const Terms = () => {
    const user = useSelector(state => state.user.userData);
    const [loading, setLoading] = useState(false);

    let terms = {};

    useEffect(() => {
        tracker.logEvent('Signup - Terms - loaded', { userType: (user.type || 'unset' ) } )
    }, [user.type])

    if( user.type === 'employer') {
        terms = EmployerTerms;
    } else if (user.type === 'employee') {
        terms = EmployeeTerms;
    } else {
        return null;
    }
    terms['date'] = firebase.firestore.FieldValue.serverTimestamp();

    const handleReject = () => {
        tracker.logEvent('Signup - Terms - rejected', { userType: (user.type || 'unset' ) } )
        alert('Du må godta for å fortsette');
    }
    const handleAccept = () => {
        setLoading(true);
        
        firebase.firestore().doc(`users/${user.uid}`).update({
            terms: {
                version: terms.version,
                date: terms.date,
                type: terms.type
            }
        });
        tracker.logEvent('Signup - Terms - accepted', { userType: (user.type || 'unset' ) } )
    }

    return ( 
        <CenterFrame>
            <Grid container spacing={3}>
                <Grid item xs={12}
                    style={{
                        height: '60vh',
                        background: '#eee',
                        border: '1px solid #ddd',
                        padding: 10,
                        borderRadius: 5,
                        overflow: 'auto',
                        textAlign: 'left'
                    }}
                >
                    {terms.content}
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleReject}
                        >
                        Avvis
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleAccept}
                        >
                        {loading ? <CircularProgress color="inherit" size={24} /> : `Godta`}
                    </Button>
                </Grid>
            </Grid>
        </CenterFrame>
     );
}
 
export default Terms;