import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import CheckIcon from '@material-ui/icons/Check';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import firebase from '../../../config/firebase';
import tracker from '../../../Helpers/tracker';
import FullscreenJobView from '../../../Views/Common/FullscreenJobView';
import UserWidget from '../../../Views/Users/UserWidget';

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        textAlign: 'center'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
        // minWidth: 120,
    },
}));


const Assigned = ({ job }) => {
    const classes = useStyles();
    const me = useSelector(state => state.user.userData);
    const assignee = useSelector(state => state.jobs.jobInterest[job.id])
    const [timeSpent, setTimeSpent] = useState({ hours: 1, minutes: 0, decimal: 1 });
    const [price, setPrice] = useState(0);
    const [hourPrice, setHourPrice] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { action = 'view' } = useParams();

    let unread = 0;
    if (
        assignee
        && assignee.chat
        && assignee.chat.count
        && assignee.chat.count > 0
        && (
            !assignee.chat[me.uid]
            || assignee.chat.count > assignee.chat[me.uid]
        )
    ) {
        unread = (assignee.chat.count - assignee.chat[me.uid]);
    }


    useEffect(() => {
        setHourPrice(
            (timeSpent.decimal * job.hourlyRate)
        )
        setPrice(
            (timeSpent.decimal * job.hourlyRate) + (job.startFee || 0)
        )
    }, [timeSpent.decimal, job.hourlyRate, job.startFee])

    if (action !== 'view') {
        return null;
    }


    const handleDialogOpen = () => {
        setDialogOpen(true);
    }
    const handleDialogClose = () => {
        setDialogOpen(false);
    }
    const handleComplete = () => {
        firebase.firestore().doc(`jobs/${job.id}`).update({
            timeSpent: {
                ...timeSpent,
                startFee: (job.startFee || 0),
                hourPrice,
                price
            },
            status: 'completed',
            assignee_phone: me.phoneNumber
        })
        tracker.logEvent('Complete job', { jobId: job.id });
    }
    const handleChangeHours = (e) => {
        setTimeSpent({
            ...timeSpent,
            hours: e.target.value,
            decimal: ((e.target.value * 60) + timeSpent.minutes) / 60
        })
    }
    const handleChangeMinutes = (e) => {
        setTimeSpent({
            ...timeSpent,
            minutes: e.target.value,
            decimal: ((timeSpent.hours * 60) + e.target.value) / 60
        })
    }
    return (
        <FullscreenJobView>
            <Grid item xs={6}>
                <UserWidget uid={job.owner} />
            </Grid>
            <Grid item xs={6}>
                <UserWidget uid={me.uid} />
            </Grid>
            <Grid item xs={12} style={{ color: '#fff', textAlign: 'left' }}>
                <Typography variant="h4" gutterBottom>Gratulerer, jobben er din!</Typography>
                <Typography variant="body1" gutterBottom>
                    Du kan kommunisere med {job.owner_name} via meldinger nedenfor.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Når jobben er ferdig, trykk på knakken nedenfor og send forespørsel om betaling til {job.owner_name}.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Badge
                    badgeContent={unread}
                    max={9}
                    color="error"
                    style={{ width: '100%' }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        aria-label="open chat"
                        component={NavLink}
                        to={`/${job.id}/chat/${me.uid}`}
                        fullWidth
                        size="large"
                    >
                        <ChatIcon className={classes.extendedIcon} />
                        Meldinger
                    </Button>
                </Badge>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDialogOpen}
                    aria-label="retract application"
                    fullWidth
                    size="large"
                >
                    <CheckIcon className={classes.extendedIcon} />
                    Jobben er ferdig!
                </Button>
            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{`Fullfør jobben og be om penger!`}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Hvor lang tid brukte du?
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="hours-select-label">Timer</InputLabel>
                                <Select
                                    labelId="hours-select-label"
                                    id="hours-select"
                                    value={timeSpent.hours}
                                    onChange={handleChangeHours}
                                    autoFocus
                                >
                                    <MenuItem value={1}>01</MenuItem>
                                    <MenuItem value={2}>02</MenuItem>
                                    <MenuItem value={3}>03</MenuItem>
                                    <MenuItem value={4}>04</MenuItem>
                                    <MenuItem value={5}>05</MenuItem>
                                    <MenuItem value={6}>06</MenuItem>
                                    <MenuItem value={7}>07</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="minutes-select-label">Minutter</InputLabel>
                                <Select
                                    labelId="minutes-select-label"
                                    id="minutes-select"
                                    value={timeSpent.minutes}
                                    onChange={handleChangeMinutes}
                                >
                                    <MenuItem value={0}>00</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={45}>45</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <table style={{ width: '100%' }} cellPadding="4" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            Timebetaling
                                            <Typography variant="caption" component="span">({job.hourlyRate} x 0{timeSpent.hours}:{timeSpent.minutes < 10 && "0"}{timeSpent.minutes})</Typography>
                                        </td>
                                        <td style={{
                                            textAlign: 'right',
                                        }}>
                                            kr. {hourPrice}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Forsikring/tjenestegebyr</td>
                                        <td style={{
                                            textAlign: 'right',
                                        }}>kr. {job.startFee || 0}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ borderTop: '1px #ccc solid' }}>Betaling</td>
                                        <td style={{
                                            textAlign: 'right',
                                            color: 'green',
                                            fontWeight: 800,
                                            fontSize: 18,
                                            borderTop: '1px #ccc solid'
                                        }}>kr. {price}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>



                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Avbryt
                    </Button>
                    <Button onClick={handleComplete} color="primary" variant="outlined">
                        Be om betaling
                    </Button>
                </DialogActions>
            </Dialog>
        </FullscreenJobView>
    )
}

export default Assigned;