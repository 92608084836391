import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import FirebaseUIAuth from "react-firebaseui-localized";
import firebase from "../../config/firebase";
import Logo from '../../Views/Logo';
import AuthWrapper from './AuthWrapper';

const uiConfig = {
    signInOptions: [{
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: "NO",
        recaptchaParameters: {
            size: 'invisible',
        }
    }],
    callbacks: {
        signInSuccessWithAuthResult: () => false,
        signInFailure: () => false,
        uiShown: () => false,
    }
};

const PhoneAuth = () => {

    useEffect(() => {
        if (firebase.apps.length) {
            firebase.apps.map((i, k) => {
                if (i.name === '[DEFAULT]-firebaseui-temp') {
                    firebase.apps[k].delete();
                }
                return i;
            })
        }
    })
    // const [showAuth, setShowAuth] = useState(false);
    // const { t } = useTranslation();
    // if (!showAuth) {
    //     return (
    //         <AuthWrapper paper={true}>
    //             <Grid container spacing={2}>
    //                 <Grid item xs={12}>
    //                     <Typography align="center" gutterBottom>
    //                         <FastfoodIcon style={{
    //                             // margin: '0 auto 20 auto',
    //                             fontSize: 48
    //                         }} />
    //                     </Typography>
    //                     <Typography variant="h5" align="center" gutterBottom>
    //                         Logg inn
    //                     </Typography>
    //                     <Typography variant="body1" align="center" gutterBottom>
    //                         {t('auth.login_or_register_to_continue')}
    //                     </Typography>
    //                 </Grid>
    //                 <Grid item sm={6} xs={12}>
    //                     <Button
    //                         fullWidth
    //                         variant="outlined"
    //                         color="primary"
    //                         onClick={() => setShowAuth(true)}
    //                     >
    //                         {t('auth.buttons.log_in')}
    //                     </Button>
    //                 </Grid>
    //                 <Grid item sm={6} xs={12}>
    //                     <Button
    //                         fullWidth
    //                         variant="contained"
    //                         color="primary"
    //                         onClick={() => setShowAuth(true)}
    //                     >
    //                         {t('auth.buttons.register')}
    //                     </Button>
    //                 </Grid>
    //             </Grid>
    //         </AuthWrapper>
    //     );
    // }
    return (
        <AuthWrapper paper={false}>
            <Logo />
            <Box>
                <FirebaseUIAuth
                    lang="no"
                    version="4.7.3"
                    config={uiConfig} 
                    auth={firebase.auth()}
                    firebase={firebase}
                />
            </Box>
        </AuthWrapper>
    );
}

export default PhoneAuth;