import { CircularProgress, Fab } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import React, { useState } from 'react';
import firebase from '../config/firebase';


const UploadPhoto = ({
    path = false,
    onComplete = null
}) => {

    const [isUploading, setIsUploading] = useState(false);
    if( !path ) {
        return;
    }

    const handleUpload = (e) => {
        let file = e.target.files[0]
        if (file === '') {
            console.error(`not an image, the image file is a ${typeof (file)}`)
        }

        var uploadTask = firebase.storage().ref(`${path}${file.name}`).put(file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // logger('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        // logger('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        // logger('Upload is running');
                        setIsUploading(true);
                        break;
                    default:
                        break;
                }
            },
            (error) => {
                console.error('An error occured: ', error );
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    if( typeof onComplete === "function" ) {
                        onComplete({ downloadURL });
                    }
                    setIsUploading(false);
                });
            }
        );
    }
    const rand = Math.round(Math.random()*100);
    const id = `fileinput-${rand}`;
    return (
        <>
            <input
                accept='image/x-png, image/jpeg'
                id={id}
                capture="camera"
                className='inputfile'
                type='file'
                onChange={handleUpload}
                style={{ 
                    display: 'none'
                }}
            />
        <label htmlFor={id}>
                <Fab
                    color="primary" 
                    aria-label="upload" 
                    size="small"
                    component="span"
                >
                    {isUploading ? <CircularProgress /> : <CameraIcon /> }
                </Fab>
        </label>
        </>
    )
}

export default UploadPhoto;