const firebaseConfig = {
    apiKey: "AIzaSyCOrIave5UfXCN7Wm_0LNUeaE1cg8o6MJc",
    authDomain: "ungcash-as.firebaseapp.com",
    projectId: "ungcash-as",
    storageBucket: "ungcash-as.appspot.com",
    messagingSenderId: "1024782624618",
    appId: "1:1024782624618:web:471984d5b68b9013e8e713",
    measurementId: "G-NNPLKL7XVT"
};

export default firebaseConfig;
