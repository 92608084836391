import firebase from '../config/firebase';

const tracker = {
    setUserId: (uid) => {
        try {
            firebase.analytics().setUserId(uid);
        } catch( err ) {
            console.error( 'Error identifying', err );
        }
    },
    setUserProperties: (data) => {
        try {
            firebase.analytics().setUserProperties(data)
        } catch( err ) {
            console.error( 'Error tracking event', err );
        }
    },
    logEvent: (e, data = {}) => {
        try {
            firebase.analytics().logEvent(e, data)
        } catch( err ) {
            console.error( 'Error tracking event', err );
        }
        
    }
}

export default tracker;