import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PWAPrompt from '../Helpers/PWAPrompt';
import Employee from './Employee';
import Employer from './Employer';
import Signup from './Users/Signup';
import ContactInfo from './Users/Signup/ContactInfo';



const Router = ({userData}) => {
    const me = useSelector(state => state.user.userData);
        
    return (
        <Signup>
            <PWAPrompt />
            <BrowserRouter>
                <Switch>
                    <Route path="/profile">
                        <ContactInfo user={me} redirectAfterSave={`/`} />
                    </Route>
                    <Route>
                        {userData.type === 'employer' ? <Employer /> : <Employee /> }
                    </Route>
                </Switch>
            </BrowserRouter>
        </Signup>
     );
}
 
export default Router;