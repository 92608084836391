import { Badge, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Typography } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import norFormatter from 'norway-number-formatter';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import tracker from '../../../Helpers/tracker';
import FullscreenJobView from '../../../Views/Common/FullscreenJobView';
import RejectDialog from '../../../Views/Jobs/RejectDialog';
import UserWidget from '../../../Views/Users/UserWidget';

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        textAlign: 'center'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}));

const Completed = ({ job }) => {
    const classes = useStyles();
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
    const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)
    const assignee = useSelector(state => (state.jobs.interestedUsers[job.id] ? state.jobs.interestedUsers[job.id][job.assigned_to] : false))
    const me = useSelector(state => state.user.userData);
    // const jobs = useSelector(state => state.jobs);

    // console.log(jobs);

    if (!assignee) return null;

    let unread = 0;
    if (
        assignee.chat
        && assignee.chat.count
        && assignee.chat.count > 0
        && (
            !assignee.chat[me.uid]
            || assignee.chat.count > assignee.chat[me.uid]
        )
    ) {
        unread = (assignee.chat.count - (assignee.chat[me.uid] || 0));
    }


    const handleRejectDialogOpen = () => {
        setRejectDialogOpen(true)
    }
    const handleRejectDialogClose = () => {
        setRejectDialogOpen(false)
    }
    const handleAcceptDialogOpen = () => {
        setAcceptDialogOpen(true)
    }
    const handleAcceptDialogClose = () => {
        setAcceptDialogOpen(false)
    }

    if (!job.timeSpent) {
        return null;
    }

    return (
        <>
            <FullscreenJobView>
                <Grid item xs={6}>
                    <UserWidget uid={me.uid} />
                </Grid>
                <Grid item xs={6}>
                    <UserWidget uid={assignee.id} />
                </Grid>
                <Grid item xs={12} style={{ color: '#fff' }}>

                    <Typography variant="h4" gutterBottom>{assignee.user.displayName} har sendt forespørsel om betaling</Typography>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <table style={{ width: '100%', textAlign: 'left' }} cellPadding="4" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            Timebetaling
                                            <Typography variant="caption" component="span">({job.hourlyRate} x 0{job.timeSpent.hours}:{job.timeSpent.minutes < 10 && "0"}{job.timeSpent.minutes})</Typography>
                                        </td>
                                        <td style={{ textAlign: 'right', }}>
                                            kr. {job.timeSpent.hourPrice}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Forsikring/tjenestegebyr</td>
                                        <td style={{ textAlign: 'right', }}>
                                            kr. {job.timeSpent.startFee || 0}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Lønn ungdom</td>
                                        <td style={{ textAlign: 'right', }}>
                                            kr. {job.timeSpent.price}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ borderTop: '1px #ccc solid' }}>Forsikring/tjenestegebyr</td>
                                        <td style={{ borderTop: '1px #ccc solid', textAlign: 'right' }}>kr. 0</td>
                                    </tr>
                                    <tr>
                                        <td style={{ borderTop: '1px #ccc solid' }}>Totalt til betaling</td>
                                        <td style={{
                                            textAlign: 'right',
                                            fontWeight: 800,
                                            fontSize: 18,
                                            borderTop: '1px #ccc solid'
                                        }}>kr. {job.timeSpent.price}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>

                        <Grid item xs={6} className={classes.actionButtons}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleRejectDialogOpen}
                                fullWidth
                                size="large"
                            >

                                Avvis krav
                            </Button>
                        </Grid>
                        <Grid item xs={6} className={classes.actionButtons}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAcceptDialogOpen}
                                size="large"
                                fullWidth
                            >

                                Godkjenn
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Badge
                        badgeContent={unread}
                        max={9}
                        color="error"
                        style={{ width: '100%' }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            aria-label="open chat"
                            component={NavLink}
                            to={`/${job.id}/chat/${job.assigned_to}`}
                            fullWidth
                            size="large"
                        >
                            <ChatIcon className={classes.extendedIcon} />
                            Meldinger
                        </Button>
                    </Badge>
                </Grid>
            </FullscreenJobView>
            <RejectDialog dialogOpen={rejectDialogOpen} handleDialogClose={handleRejectDialogClose} job={job} />
            <AcceptDialog dialogOpen={acceptDialogOpen} handleDialogClose={handleAcceptDialogClose} job={job} />
        </>
    )
    // return (
    //     <BottomBar>
    //         <Grid item xs={12}>
    //             <Typography variant="body1" align="center">{assignee.user.displayName} har sendt forespørsel om betaling</Typography>
    //         </Grid>
    //         <Grid item xs={4}>
    //             <Typography variant="body2" align="center">Tidsbruk</Typography>
    //             <Typography variant="h3" align="center" color="primary">
    //                 {job.timeSpent.hours}:{job.timeSpent.minutes}
    //             </Typography>
    //         </Grid>
    //         <Grid item xs={4}>
    //             <Typography variant="body2" align="center">Timepris</Typography>
    //             <Typography variant="h3" align="center" color="primary">
    //                 {job.hourlyRate},-
    //             </Typography>
    //         </Grid>
    //         <Grid item xs={4}>
    //             <Typography variant="body2" align="center">Betaling</Typography>
    //             <Typography variant="h3" align="center" color="primary">
    //                 {job.timeSpent.price},-
    //             </Typography>
    //         </Grid>
    //         <Grid item xs={6} className={classes.actionButtons}>
    //             <Button
    //                 variant="outlined"
    //                 color="secondary"
    //                 size="medium"
    //                 onClick={handleRejectDialogOpen}
    //             >
    //                 <PaymentIcon className={classes.extendedIcon} />
    //                 Avvis krav
    //             </Button>
    //         </Grid>
    //         <Grid item xs={6} className={classes.actionButtons}>
    //             <Button
    //                 variant="contained"
    //                 size="medium"
    //                 color="primary"
    //                 onClick={handleAcceptDialogOpen}
    //             >
    //                 <PaymentIcon className={classes.extendedIcon} />
    //                 Godkjenn
    //             </Button>
    //         </Grid>
    //         <RejectDialog dialogOpen={rejectDialogOpen} handleDialogClose={handleRejectDialogClose} job={job} />
    //         <AcceptDialog dialogOpen={acceptDialogOpen} handleDialogClose={handleAcceptDialogClose} job={job} />
    //     </BottomBar>
    // )
}

const AcceptDialog = ({ dialogOpen, handleDialogClose, job }) => {
    const handleComplete = () => {
        tracker.logEvent('Payment is made', { jobId: job.id })
        handleDialogClose();
    }
    return (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>{`Godkjenn kravet`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="body1">
                        Vipps <strong>{job.timeSpent.price}</strong> kroner til <strong>{norFormatter(job.assignee_phone.replace("+47", ""), 'phone_number')}</strong> for å gjøre opp for jobben.
                    </Typography>

                    <a href={`https://qr.vipps.no/28/2/01/031/${job.assignee_phone.replace("+", "")}?v=1`}>Klikk her for å åpne Vipps</a>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="secondary">
                    Avbryt
                </Button>
                <Button onClick={handleComplete} color="primary" variant="outlined">
                    Pengene er sendt
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Completed