import React from 'react';
import { useParams } from 'react-router';
import Archived from './Archived';
import Assigned from './Assigned';
import Completed from './Completed';
import Draft from './Draft';
import Paid from './Paid';
import Published from './Published';

const EmployerActions = ({job}) => {
    const { 
        // id = false, 
        action = 'view' 
    } = useParams();
    
    if( action === 'chat') return null
    
    // if( action === 'view') return (
    //     <>
    //         <p>Draft:</p>
    //         <Draft job={job} />
    //         <p>Published:</p>
    //         <Published job={job} />
    //         <p>Assigned:</p>
    //         <Assigned job={job} />
    //         <p>Completed:</p>
    //         <Completed job={job} />
    //         <p>Paid:</p>
    //         <Paid job={job} />
    //         <p>Archived:</p>
    //         <Archived job={job} />
    //     </>
    // )


    switch (job.status) {
        case 'draft': 
            return <Draft job={job} />

        case 'published': 
            return <Published job={job} />

        case 'assigned': 
            return <Assigned job={job} />

        case 'completed': 
            return <Completed job={job} />

        case 'paid': 
            return <Paid job={job} />

        case 'archived': 
            return <Archived job={job} />

        default:
            return <Draft job={job} />
    }
}

export default EmployerActions;