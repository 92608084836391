import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";
import firebaseConfig from './firebaseConfig';

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    firebase.firestore().useEmulator("localhost", 8080);
    firebase.auth().useEmulator("http://localhost:9099");
}
firebase.performance();

firebase.auth().useDeviceLanguage();
// if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {

//     firebase.firestore().enablePersistence()
//         .catch(function (err) {
//             if (err.code === 'failed-precondition') {
//                 // Multiple tabs open, persistence can only be enabled
//                 // in one tab at a a time.
//                 // ...
//                 console.log(err);
//             } else if (err.code === 'unimplemented') {
//                 // The current browser does not support all of the
//                 // features required to enable persistence
//                 console.log(err);
//             }
//     });
// }


export default firebase;