import firebase from '../config/firebase';
import logger from '../Helpers/logger';
import tracker from '../Helpers/tracker';
import { setListener } from "./helper";

const initialState = {
    jobs: {},

    jobInterest: {},

    filteredJobs: [],

    filters: {},

    interestedUsers: {},

    interestedUsersListenerId: false,

    jobsLoaded: false

}

const jobs = (state = initialState, action) => {
    let tmp;
    switch (action.type) {
        case '@helper/resetState':
            return initialState;

        case '@jobs/addJob':
            tmp = {};
            tmp[action.id] = action.job;
            return { ...state, jobs: { ...state.jobs, ...tmp } };

        case '@jobs/setJob':
            tmp = {
                ...state.jobs
            }
            tmp[action.id] = action.data;
            return { ...state, jobs: tmp }

        case '@jobs/setJobInterest':
            tmp = {
                ...state.jobInterest
            }
            tmp[action.id] = action.data;
            return { ...state, jobInterest: tmp }

        case '@jobs/setFilteredJobs':
            return { ...state, filteredJobs: action.jobs }

        case '@jobs/setFilters':
            return { ...state, filters: action.filters }

        case '@jobs/setInterestedUsers':
            tmp = {
                ...state.interestedUsers
            }
            tmp[action.jobId][action.userId] = action.data;
            return { ...state, interestedUsers: { ...tmp } }

        case '@jobs/resetInterestedUsers':
            tmp = {
                ...state.interestedUsers
            }
            tmp[action.jobId] = {};
            return { ...state, interestedUsers: tmp }

        case '@jobs/setJobInterestedUsersListenerId':
            return { ...state, interestedUsersListenerId: action.id }

        case '@jobs/setJobsLoaded':
            return { ...state, jobsLoaded: action.status }

        default:
            return state;
    }
}

const addFilter = (filter) => (dispatch, getState) => {
    dispatch(
        setFilters({
            ...getState().jobs.filters,
            ...filter
        })
    )
}
const removeFilter = (key) => (dispatch, getState) => {
    let filters = getState().jobs.filters;
    delete filters[key];
    dispatch(
        setFilters(
            filters
        )
    )
}

const setFilters = (filters) => (dispatch, getState) => {
    dispatch(
        doSetFilters(filters)
    )
    dispatch(
        filterJobs()
    )
}
const setFilteredJobs = (jobs) => ({
    type: '@jobs/setFilteredJobs',
    jobs
})

const filterJobs = () => (dispatch, getState) => {
    let filters = getState().jobs.filters;
    let allJobs = getState().jobs.jobs;
    let filteredJobs = Object.keys(allJobs);

    // Only published jobs:
    filteredJobs = filteredJobs.filter(k => allJobs[k].status === 'published')

    if (filters.category) {
        filteredJobs = filteredJobs.filter(k => allJobs[k].category === filters.category)
    }

    dispatch(
        setFilteredJobs(filteredJobs)
    )
}
const doSetFilters = (filters) => ({
    type: '@jobs/setFilters',
    filters
})
const setJob = (id, data) => ({
    type: '@jobs/setJob',
    id,
    data
})
const setJobInterest = (id, data) => ({
    type: '@jobs/setJobInterest',
    id,
    data
})
const setJobsLoaded = (status) => ({
    type: '@jobs/setJobsLoaded',
    status
})

const watchJob = (id) => (dispatch, getState) => {
    try {
        if (getState().jobs.jobs[id]) {
            return false;
        }
        if (typeof getState().helper.listeners[`job-${id}`] === "function") return false;

        const unsubscribe = firebase.firestore().doc(`jobs/${id}`)
            .onSnapshot((item) => {
                if (!item.exists) return;

                dispatch(setJob(
                    item.id,
                    {
                        id: item.id,
                        ...item.data()
                    }
                ))

                dispatch(
                    watchJobInterest(item.id)
                )
                dispatch(
                    watchInterestedUsers({ id: item.id, ...item.data() })
                )
                return item;
            })
        if (typeof unsubscribe === "function") {
            dispatch(
                setListener(`job-${id}`, unsubscribe)
            )
        }
    } catch (err) {
        logger(err);
    }
}
const watchJobInterest = (id) => (dispatch, getState) => {
    try {
        if (getState().jobs.jobInterest[id]) return false;
        if (typeof getState().helper.listeners[`job-interest-${id}`] === "function") return false;

        let user = getState().user.userData;
        if (!user || user.type !== 'employee' || !user.uid) return;


        const unsubscribe = firebase.firestore().doc(`jobs/${id}/users/${user.uid}`)
            .onSnapshot((item) => {
                if (!item.exists) return;
                dispatch(setJobInterest(
                    id,
                    {
                        id: item.id,
                        ...item.data()
                    }
                ))

                return item;
            })
        logger(getState().jobs.jobInterest);
        if (typeof unsubscribe === "function") {
            dispatch(
                setListener(`job-interest-${id}`, unsubscribe)
            )
        }
    } catch (err) {
        logger(err);
    }
}

const watchMyJobs = () => (dispatch, getState) => {
    try {
        if (getState().helper.listeners.myjobs) {
            return false;
        }
        let user = getState().user.userData,
            uid = user.uid;

        if (!uid) {
            throw Error('UID not found');
        }

        const callback = (querySnap) => {
            let tmp = [];
            dispatch(setJobsLoaded(true))
            querySnap.docs.map((item) => {
                dispatch(setJob(
                    item.id,
                    {
                        id: item.id,
                        ...item.data()
                    }
                ))
                return item;
            })
            return tmp;
        }

        const collection = firebase.firestore().collection('jobs');
        let unsubscribe;
        if (user.type === 'employee') {
            unsubscribe = collection.where('assigned_to', '==', uid).where('status', 'in', ['assigned', 'completed', 'paid']).onSnapshot(callback)
        } else {
            unsubscribe = collection.where('owner', '==', uid).onSnapshot(callback)
        }
        if (typeof unsubscribe === "function") {
            dispatch(
                setListener(`myjobs`, unsubscribe)
            )
        }
    } catch (err) {
        logger(err);
    }

}
const watchPublishedJobs = () => (dispatch, getState) => {
    try {
        if (getState().helper.listeners.filteredJobs && typeof getState().helper.listeners.filteredJobs === "function") {
            return;
        }
        // const filters = getState().jobs.filters;
        var jobRef = firebase.firestore().collection('jobs')
            .where("status", "==", "published")
            .where("date", ">=", new Date())
            .orderBy('date');

        const unsubscribe = jobRef
            .onSnapshot((querySnap) => {
                // console.log( querySnap.docs);
                let tmp = [];
                querySnap.docs.map((item) => {
                    dispatch(setJob(
                        item.id,
                        {
                            id: item.id,
                            ...item.data()
                        }
                    ))
                    dispatch(filterJobs());

                    return item;
                })
                return tmp;
            })

        if (typeof unsubscribe === "function") {
            dispatch(
                setListener(`filteredJobs`, unsubscribe)
            )
        }
    } catch (err) {
        logger(err);
    }

}

const trackView = (jobId) => (dispatch, getState) => {
    let user = getState().user.userData;
    if (user.type !== 'employee' || !user.uid) return;

    firebase.firestore().doc(`jobs/${jobId}/users/${user.uid}`).set({
        viewed_at: firebase.firestore.FieldValue.serverTimestamp(),
        views: firebase.firestore.FieldValue.increment(1)
    }, {
        merge: true
    }).catch(err => logger(err))

    dispatch(
        watchJobInterest(jobId)
    )

}

const markAsInteresting = (jobId) => (dispatch, getState) => {
    let user = getState().user.userData;
    if (user.type !== 'employee' || !user.uid) return;

    // let job = getState().jobs.jobs[jobId];

    firebase.firestore().doc(`jobs/${jobId}/users/${user.uid}`).set({
        user: {
            displayName: user.displayName || '',
            firstName: user.firstName || '',
            photoURL: user.photoURL || '',
            postArea: user.postArea || '',
        },
        interested: true,
        interested_at: firebase.firestore.FieldValue.serverTimestamp()
    }, {
        merge: true
    }).catch(err => logger(err))

    tracker.logEvent('Apply to job', { jobId: jobId })

    // firebase.firestore().collection(`jobs/${jobId}/notifications/`).add({ ///////////////// MOVE TO FUNCTIONS!
    //     user: {
    //         displayName: user.displayName || '',
    //         firstName: user.firstName || '',
    //         photoURL: user.photoURL || '',
    //         postArea: user.postArea || '',
    //     },
    //     from: user.uid,
    //     to: job.owner,
    //     type: 'shown-interest',
    //     read: false,
    //     created_at: firebase.firestore.FieldValue.serverTimestamp()
    // }).catch(err => logger( err ))
}

const setInterestedUsers = (jobId, userId, data) => ({
    type: '@jobs/setInterestedUsers',
    jobId,
    userId,
    data
})
const resetInterestedUsers = (jobId) => ({
    type: '@jobs/resetInterestedUsers',
    jobId
})

const watchInterestedUsers = (job) => (dispatch, getState) => {
    try {
        if (typeof getState().helper.listeners[`job-interested-users`] === "function" && getState().jobs.interestedUsersListenerId === (job.id || job)) return false;

        if (typeof job === 'string') {
            if (!getState().jobs.jobs[job]) {
                dispatch(
                    watchJob(job)
                )
            }
            job = getState().jobs.jobs[job];
        }

        let user = getState().user.userData;
        if (!user || user.uid !== job.owner) return;

        var ref = firebase.firestore().collection(`jobs/${job.id}/users`)
            .where('interested', '==', true);

        const unsubscribe = ref.onSnapshot((querySnapshot) => {
            dispatch(resetInterestedUsers(job.id))

            querySnapshot.forEach((item) => {
                dispatch(setInterestedUsers(
                    job.id,
                    item.id,
                    {
                        id: item.id,
                        ...item.data()
                    }
                ))
            }, (error) => {
                console.error(error)
            });

            return querySnapshot;
        })
        logger(getState().jobs.jobInterest);
        if (typeof unsubscribe === "function") {
            dispatch(
                setJobInterestedUsersListenerId(job.id)
            )
            dispatch(
                setListener(`job-interested-users`, unsubscribe)
            )
        }
    } catch (err) {
        logger(err);
    }
}

const setJobInterestedUsersListenerId = (id) => ({
    type: '@jobs/setJobInterestedUsersListenerId',
    id
})


export default jobs;
export { watchMyJobs, watchJob, setFilters, addFilter, removeFilter, watchPublishedJobs, trackView, markAsInteresting, watchInterestedUsers };