import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import JobDate from '../../Helpers/JobDate';
import UserPresentation from '../Users/UserPresentation';
import placeholder from '../../assets/placeholder.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: 20,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto'
    },
    cover: {
        width: 151,
    },
    media: {
        height: 140,
    },
}));

const ListItem = ({ item }) => {
    const classes = useStyles();
    if (item === false) return null;

    return (
        <>
            {/* <NavLink to={`/${item.id}`} style={{textDecoration: 'none'}}>
                <Card className={classes.root}>
                    {item.photos && item.photos[0] && 
                        <CardMedia
                            className={classes.cover}
                            image={item.photos[0]}
                        />
                    }
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {item.title}
                            </Typography>
                            <Typography gutterBottom variant="body1">
                                {item.description}
                            </Typography>
                        </CardContent>
                    </div>
                </Card>
            </NavLink> */}
            <Card style={{
                marginTop: 20,
                marginBottom: 20
            }}>
                {/* <CardHeader 
                    title={item.title}
                /> */}
                <CardActionArea
                    component={NavLink}
                    to={`/${item.id}`}
                >
                    {/* {(item.photos && item.photos[0]) && */}
                    <CardMedia
                        className={classes.media}
                        image={(item.photos && item.photos[0]) ? item.photos[0] : placeholder}
                    />
                    {/* } */}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {item.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            {item.description}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            Innen: <JobDate date={item.date} />
                        </Typography>
                        <Divider />
                        <Box style={{ marginTop: 15 }}>
                            <UserPresentation uid={item.owner} />
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    )
}

export default ListItem;