import { ButtonBase, Grid } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';

const PhotoModal = ({ photos, handleClose }) => {
    const [current, setCurrent] = useState(0);
    if (!photos || photos.length < 1) {
        handleClose();
        return null;
    }
    photos.filter(i => i.length > 3);

    const next = () => {
        if (current + 1 < photos.length) {
            setCurrent(current + 1);
        } else {
            setCurrent(0);
        }
    }
    const prev = () => {
        if (current > 0) {
            setCurrent(current - 1);
        } else {
            setCurrent(photos.length - 1);
        }
    }
    return (

        <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
                position: 'fixed',
                width: '100%',
                left: '0',
                top: '20%'
            }}
        >
            <ButtonBase onClick={handleClose} style={{ position: 'fixed', top: 10, right: 10 }}>
                <CloseIcon style={{
                    fontSize: 48
                }} />
            </ButtonBase>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
                <ButtonBase onClick={prev}>
                    <ChevronLeftIcon style={{
                        fontSize: 48
                    }} />
                </ButtonBase>
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
                <img src={photos[current] || ''} style={{ maxWidth: '100%', maxHeight: '70vh' }} alt={`Illustration ${current}`} />
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
                <ButtonBase onClick={next}>
                    <ChevronRightIcon style={{ fontSize: 48 }} />
                </ButtonBase>
            </Grid>
        </Grid>
    )
}

export default PhotoModal;