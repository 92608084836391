const initialState = {
    showAppBar: false,
    title: null,

    offset: true,
    progress: false,

}

const gui = (state = initialState, action) => {
    switch (action.type) {
        case '@helper/resetState':
            return initialState;

        case '@gui/showAppBar':
            return { ...state, showAppBar: action.show }
        
        case '@gui/setTitle':
            return { ...state, title: action.title, progress: false }

        case '@gui/setProgress':
            return { ...state, progress: action.val }
       
        default:
            return state;
    }
}

const setTitle = (title) => ({
    type: '@gui/setTitle',
    title
})

const setAppBarProgress = (val) => ({
    type: '@gui/setProgress',
    val
})

const setAppBarVisibility = (show) => ({
    type:  '@gui/showAppBar',
    show
})
const hideAppBar = () => ({
    type:  '@gui/showAppBar',
    show: false
})
const showAppBar = () => ({
    type: '@gui/showAppBar',
    show: true
})


export default gui;
export { setTitle, hideAppBar, showAppBar, setAppBarVisibility, setAppBarProgress };