import { Button, Card, CardActionArea, CardContent, CardMedia, Fab, Grid, makeStyles, Modal, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import placeholder from '../../assets/placeholder.jpg';
import firebase from '../../config/firebase';
import JobDate from '../../Helpers/JobDate';
import logger from '../../Helpers/logger';
import { trackView, watchJob } from '../../redux/jobs';
import { getPublicData } from '../../redux/user';
import CenterFrame from '../../Views/CenterFrame';
import PhotoModal from '../../Views/Common/PhotoModal';
import TopBar from '../../Views/Common/TopBar';
import UserPresentation from '../../Views/Users/UserPresentation';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 20
    },
    highlightWrapper: {
        textAlign: 'center',
        "&:nth-child(2)": {
            borderLeft: '1px solid #ccc',
            borderRight: '1px solid #ccc'
        }
    },
    highlightTitle: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    highlightCaption: {
        color: theme.palette.grey[500]
    },
    actionButtons: {
        textAlign: 'center'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    editButton: {
        float: 'right',
        // top: -10,
        // right: -10,
    },
    editIcon: {
        marginLeft: theme.spacing(1)
    }
}));

const ViewJob = (props) => {
    const classes = useStyles();
    const { id = false } = useParams();
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const job = useSelector(state => state.jobs.jobs[id]);
    const user = useSelector(state => state.user.userData);
    const owner = useSelector(state => {
        if (!job) return null;
        return state.user.public[job.owner]
    });
    const [open, setOpen] = React.useState(false);
    const history = useHistory()

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (loaded || !id) return false;
        dispatch(
            watchJob(id)
        );
        dispatch(
            trackView(id)
        )
        setLoaded(true);
    }, [loaded, dispatch, id])

    useEffect(() => {
        if (owner || !job) return false;
        dispatch(
            getPublicData(
                job.owner
            )
        )
    }, [job, dispatch, owner])


    if (!job) {
        return (
            <>
                <TopBar title="Ikke funnet" backButton={true} backPath="/" offset={false} />
                <CenterFrame>
                    Job not found
                </CenterFrame>
            </>
        )
    }

    const estimates = (time, rate, startFee = 0) => {
        let res = {
            estTime: `3+ timer`,
            estPayout: `${(rate * 3) + startFee}+`
        }
        if (time === 1) {
            res.estTime = `1 time`
            res.estPayout = `${(rate * 1) + startFee}`
        }
        if (time === 2) {
            res.estTime = `1-3 timer`
            res.estPayout = `${(rate * 1) + startFee} - ${(rate * 3) + startFee}`
        }
        return res
    }
    const { estTime, estPayout } = estimates(job.timeEstimate, job.hourlyRate, job.startFee);

    const deleteJob = () => {
        let doDelete = window.confirm('Er du sikker på at du ønsker å slette jobben? Dette kan ikke angres.');
        if (doDelete) {

            firebase.firestore().doc(`jobs/${job.id}`).update({ status: 'deleted' })
                .then(() => {
                    history.push('/');
                })
                .catch(err => {
                    logger(err);
                });

        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropProps={{
                    style: {
                        backgroundColor: '#fff'
                    }
                }}
            >
                <>
                    <PhotoModal photos={job.photos} handleClose={handleClose} />
                </>
            </Modal>
            <Card className={classes.root}>
                {job.photos.length > 0 ?
                    <CardActionArea onClick={handleOpen}>
                        <CardMedia
                            component="img"
                            alt={job.title}
                            height="230"
                            image={job.photos[0]}
                            title={job.title}
                        />
                    </CardActionArea>
                    :
                    <CardMedia
                        component="img"
                        alt={job.title}
                        height="230"
                        image={placeholder}
                        title={job.title}
                    />
                }
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={4} className={classes.highlightWrapper}>
                            <Typography variant="h3" className={classes.highlightTitle}>
                                {job.hourlyRate}
                            </Typography>
                            <Typography variant="body1" className={classes.highlightCaption}>
                                kr/t<br />
                                {/* {job.startFee && `${job.startFee} Forsikring/tjenestegebyr`} */}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.highlightWrapper}>
                            <Typography variant="h3" className={classes.highlightTitle}>
                                {estTime}
                            </Typography>
                            <Typography variant="body1" className={classes.highlightCaption}>
                                Forventet tidsbruk
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.highlightWrapper}>
                            <Typography variant="h3" className={classes.highlightTitle}>
                                {estPayout}
                            </Typography>
                            <Typography variant="body1" className={classes.highlightCaption}>
                                Forventet Betaling
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            {job.owner === user.uid &&
                                <Fab
                                    color="primary"
                                    size="small"
                                    component={NavLink}
                                    to={`/${id}/edit/`}
                                    className={classes.editButton}
                                >
                                    <EditIcon />
                                </Fab>
                            }
                            <Typography variant="h2">{job.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Beskrivelse</Typography>
                            <Typography variant="body1">{job.description}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Ønskes utført innen</Typography>
                            <Typography variant="body1"><JobDate date={job.date} /></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Addresse</Typography>
                            <Typography variant="body1">{job.address}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Oppdragsgiver</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <UserPresentation uid={job.owner} />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>


            {job.owner === user.uid && (!job.interestedUsers || job.interestedUsers.length < 1) &&
                <>
                    <Typography variant="h5">Ønsker du å slette jobben?</Typography>
                    <Typography variant="body1" gutterBottom>Du kan slette jobber som ikke ennå har fått søkere. Ønsker du å slette denne jobben?</Typography>
                    <Typography variant="body1" gutterBottom>Merk: dette kan ikke angres</Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={deleteJob}
                    >
                        Slett jobb
                    </Button>
                </>
            }
        </>
    );
}



export default ViewJob;