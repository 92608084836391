import React from 'react';
import { useSelector } from 'react-redux';
import TopBar from '../../Views/Common/TopBar';
import AddJobFab from '../../Views/Jobs/AddJobFab';
import ListEmpty from '../../Views/Jobs/ListEmpty';
import ListSection from '../../Views/Jobs/ListSection';
import Loader from '../../Views/Loader';

const JobList = () => {
    const userData = useSelector(state => state.user.userData);
    const loaded = useSelector(state => state.jobs.jobsLoaded);
    const jobs = useSelector(state => state.jobs.jobs);

    if( !loaded ) return <Loader />

    if( !jobs || Object.keys(jobs).length === 0) return (
        <>
            <TopBar title={`Dine jobber`} offset={false} />
            <ListEmpty />
            <AddJobFab />
        </>
    )

    return (
        <>
            <TopBar title={`Dine jobber`} />

            <ListSection title={`Fullført - Venter på betaling`} items={Object.keys(jobs).map(key => {
                let job = jobs[key];
                if (job.status === 'completed' && job.owner === userData.uid) return jobs[key]
                return false;
            })} />


            <ListSection title={`Venter på søkere`} items={Object.keys(jobs).map(key => {
                let job = jobs[key];
                if (job.status === 'published' && job.owner === userData.uid) return jobs[key]
                return false;
            })} />
            <ListSection title={`Tildelt`} items={Object.keys(jobs).map(key => {
                let job = jobs[key];
                if (job.status === 'assigned' && job.owner === userData.uid) return jobs[key]
                return false;
            })} />

            <ListSection title={`Kladd`} items={Object.keys(jobs).map(key => {
                let job = jobs[key];
                if (job.status === 'draft' && job.owner === userData.uid) return jobs[key]
                return false;
            })} />

            <ListSection title={`Betalt / fullført`} items={Object.keys(jobs).map(key => {
                let job = jobs[key];
                if (job.status === 'paid' && job.owner === userData.uid) return jobs[key]
                return false;
            })} />

            <AddJobFab />
        </>
    )
}
 
export default JobList;