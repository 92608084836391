import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import React from 'react';
import useStyles from '../../assets/styles';

const AuthWrapper = (props) => {
    const classes = useStyles();
    if (props.paper !== false) {
        return (
            <div className={classes.background}>
                <Container maxWidth="xs" fixed className={classes.root}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            {props.children}
                        </CardContent>
                    </Card>
                </Container>
            </div>
        );
    }
    return (
        <div className={classes.background}>
            <Container maxWidth="xs" fixed className={classes.root}>
                {props.children}
            </Container>
        </div>
    );
}

export default AuthWrapper;