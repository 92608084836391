import { Button, Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PhoneAuth from './Components/Auth/PhoneAuth';
import Router from './Components/Router';
import firebase from './config/firebase';
import { watchUser } from './redux/user';
import CenterFrame from './Views/CenterFrame';
import Loader from './Views/Loader';
import Logo from './Views/Logo';
import useHotjar from 'react-use-hotjar';

function App() {
    const { initHotjar } = useHotjar();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector(state => state.user.user)
    const userData = useSelector(state => state.user.userData);
    const isLoaded = useSelector(state => state.user.isLoaded);

    useEffect(() => {
        dispatch(
            watchUser()
        );
    }, [dispatch])

    useEffect(() => {
        initHotjar(2430610, 6);
    }, [initHotjar]);
    


    if (!isLoaded) {
        return <Loader />
    }
    
    if (!user) {
        return <PhoneAuth />
    }
    
    if (!userData) {
        return <Loader />
    }
    
    if (!userData.type) {
        return (
            <CenterFrame>
                <Grid container>
                    <Grid item xs={12}>
                        <Logo />
                        <Typography variant="h4" gutterBottom>
                            Velg din rolle
                        </Typography>
                    </Grid>
                    <Grid item xs={12}
                        style={{
                            background: '#fff',
                            padding: 20,
                            marginBottom: 20
                        }}>
                        <Typography variant="h5" gutterBottom>
                            Arbeidssøker
                </Typography>
                        <Typography variant="body1" gutterBottom>
                            Jeg ønsker å hjelpe folk i nærmiljøet med å utføre småjobber.
                </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth
                            style={{ marginBottom: 20 }}
                            onClick={() => {
                                firebase.firestore().doc(`users/${user.uid}`).set({
                                    type: 'employee'
                                }, { merge: true })
                            }}
                        >
                            {t('Employee')}
                        </Button>
                    </Grid>

                    <Grid item xs={12}
                        style={{
                            background: '#fff',
                            padding: 20
                        }}>
                        <Typography variant="h5" gutterBottom>
                            Oppdragsgiver
                </Typography>
                        <Typography variant="body1" gutterBottom>
                            Jeg ønsker å støtte ungdom i lokalmiljøet samtidig som jeg får hjelp til å utføre småjobber.
                </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth
                            style={{ marginBottom: 20 }}
                            onClick={() => {
                                firebase.firestore().doc(`users/${user.uid}`).set({
                                    type: 'employer'
                                }, { merge: true })
                            }}
                        >
                            {t('Employer')}
                        </Button>
                    </Grid>
                </Grid>
            </CenterFrame>
        )
    }

    return <Router userData={userData} />
}

export default App;