import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import firebase from '../../config/firebase';
import logger from '../../Helpers/logger';
import CenterFrame from '../../Views/CenterFrame';
import TopBar from '../../Views/Common/TopBar';
import categories from '../../config/JobCategories';
import tracker from '../../Helpers/tracker';

const CreateJob = () => {
    const [error, setError] = useState(false);
    const userData = useSelector(state => state.user.userData);

    const history = useHistory();

    useEffect(() => {
        tracker.logEvent('Create job - loaded')
    }, [])


    const handleClick = (category) => {
        firebase.firestore().collection('jobs').add({
            category,
            owner: userData.uid,
            owner_name: userData.displayName,
            address: `${userData.address}, ${userData.postCode} ${userData.postArea}`,
            terms: false,
            status: 'draft',
            photos: [],
            hourlyRate: 180,
            startFee: 0
        }).then((data) => {
            tracker.logEvent('Create job - loaded')
            history.push(`/${data.id}/edit/`);
        })
            .catch(err => {
                logger(err);
                setError(true)
            });
    }



    if (error) return (
        <CenterFrame>
            <Typography variant="body1">
                Det oppstod en feil. Prøv på nytt!
            </Typography>
        </CenterFrame>
    )

    return (
        <>
            <TopBar title={`Opprett jobb`} offset={false} backButton={true} backPath="/" />
            <CenterFrame>
                <Typography
                    variant="h1"
                    style={{
                        marginBottom: 30
                    }}
                >
                    Velg en kategori
                </Typography>
                <Grid container spacing={3}>
                    {Object.keys(categories).map((key) => {
                        let obj = categories[key];
                        return (
                            <Grid item xs={6} key={key}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleClick(obj.key)}
                                    fullWidth
                                >
                                    {obj.label}
                                </Button>
                            </Grid>
                        )
                    })}
                </Grid>
            </CenterFrame>
        </>
    )

}

export default CreateJob;