import firebase from '../config/firebase';
import logger from '../Helpers/logger';
import tracker from '../Helpers/tracker'
import { killListener, resetState, setListener } from './helper';
import { watchMyJobs } from './jobs';

const initialState = {
    isLoaded: false,
    user: null,

    userData: null,
    userDataListener: null,

    refreshListener: null,

    public: {}
}

const user = (state = initialState, action) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        logger(action);
    }
    let tmp;
    switch (action.type) {
        case '@helper/resetState':
            return initialState;
        case '@user/setUser':
            return { ...state, user: action.user, isLoaded: true }
        
        case '@user/setUserData':
            return { ...state, userData: action.userData }
        case '@user/setUserDataListener':
            return { ...state, userDataListener: action.listener }
        case '@user/setRefreshListener':
            return { ...state, refreshListener: action.listener }

        case '@user/setPublicData':
            tmp = { ...state }
            tmp.public[action.uid] = action.data;
            return tmp;

        default:
            return state;
    }
}

const doSetPublicData = (uid, data) => ({
    type: '@user/setPublicData',
    uid,
    data
})

const getPublicData = (uid) => (dispatch, getState) => {
    if( getState().user.public[uid] ) return;
    if( !uid ) return;
    dispatch(doSetPublicData( uid, {} ))
    
    firebase.firestore().doc(`/users/${uid}/meta/public`)
        .get()
        .then((doc) => {
            if (doc.exists) {
                dispatch(
                    doSetPublicData( uid, doc.data() )
                )
            }
        })
        .catch(err => {
            dispatch(doSetPublicData( uid, false ))
            logger( err )
        });
}


const setUser = (userData) => {
    return {
        type: '@user/setUser',
        user: userData
    }
}

const logOut = () => {
    return function (dispatch, getState) {

        dispatch( killListener('userData') )
        dispatch( killListener('refreshListener') )

        firebase.auth().signOut().then(() => {
            dispatch(resetState())
        }).catch((error) => {
            // An error happened.
        });
    }
}

const setRefreshListener = () => {
    return function (dispatch, getState) {
        if( getState().helper.listeners.refreshListener ) {
            return true;
        }
        dispatch(
            setListener(
                'refreshListener',
                firebase.firestore().doc('users/' + firebase.auth().currentUser.uid + '/meta/refresh' ).onSnapshot(snap => {
                    firebase.auth().currentUser.getIdTokenResult(true)
                        .catch((error) => {
                            logger(error);
                        });
                    return true;
                })
            )
        )
    }
}

const setUserData = (userData) => ({
    type: '@user/setUserData',
    userData
})


const watchUserData = () => {
    return function (dispatch, getState) {
        if(getState().helper.listeners.userData || !getState().user.user) {
            return;
        }
        
        dispatch(
            setListener(
                'userData',
                firebase.firestore().doc(`/users/${getState().user.user.uid}`).onSnapshot((snap) => {
                    if (!snap.exists) {
                        firebase.firestore().doc(`/users/${getState().user.user.uid}`).set({
                            displayName: getState().user.user.displayName || "",
                            phoneNumber: getState().user.user.phoneNumber,
                            uid: getState().user.user.uid
                        }, { merge: true })
                        return
                    }
                    tracker.setUserId(getState().user.user.uid);
                    dispatch(
                        setUserData({
                            ...snap.data()
                        })
                    )
                    dispatch(
                        watchMyJobs()
                    )
                    if( snap.data().type ) {
                        tracker.setUserProperties({ userType: snap.data().type })
                    }
                })
            )
        )
    }
}

const watchUser = () => {
    return function (dispatch, getState ) {
        return firebase.auth().onAuthStateChanged(
            (user) => {
                if( user ) {
                    dispatch(setUser({
                        phoneNumber: user.phoneNumber,
                        photoURL: user.photoURL,
                        isAnonymous: user.isAnonymous,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        displayName: user.displayName,
                        uid: user.uid
                    }));
                    dispatch(watchUserData());
                    dispatch(setRefreshListener())
                } else {
                    dispatch(setUser(null));
                    dispatch( killListener('userData') )
                    dispatch( killListener('refreshListener') )
                }
            }, (err) => {
                logger(err);
            })
    }
}

export default user;
export { watchUser, logOut, getPublicData };
