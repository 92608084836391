import { Box, Button, Fab, FormControl, FormControlLabel, makeStyles, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Slider, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import firebase from '../../config/firebase';
import categories, { jobs } from '../../config/JobCategories';
import logger from '../../Helpers/logger';
import tracker from '../../Helpers/tracker';
import UploadPhoto from '../../Helpers/UploadPhoto';
import { watchJob } from '../../redux/jobs';
import Loader from '../../Views/Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 20
    },
    highlightWrapper: {
        textAlign: 'center',
        "&:nth-child(2)": {
            borderLeft: '1px solid #ccc',
            borderRight: '1px solid #ccc'
        }
    },
    highlightTitle: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    highlightCaption: {
        color: theme.palette.grey[500]
    },
    actionButtons: {
        textAlign: 'center'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    editButton: {
        float: 'right',
        // top: -10,
        // right: -10,
    },
    editIcon: {
        marginLeft: theme.spacing(1)
    }
}));

const EditJob = (props) => {
    const classes = useStyles();
    const { id = false } = useParams();
    const [jobData, setJobData] = useState(null);
    const [jobRadio, setJobRadio] = useState("");
    const [localJobData, setLocalJobData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const userData = useSelector(state => state.user.userData);
    const dispatch = useDispatch();
    const storeJobData = useSelector(state => state.jobs.jobs[id]);
    const history = useHistory()


    useEffect(() => {
        if (!jobData || !jobData.category) return;
        Object.keys(jobs[jobData.category]).map((i) => {
            let job = jobs[jobData.category][i];
            if (job.label === jobData.title) {
                setJobRadio(job.key);
            }
            return i;
        })
    }, [jobData]);

    useEffect(() => {
        if (loaded) return false;

        dispatch(
            watchJob(id)
        );
        setLoaded(true);

        tracker.logEvent('Edit job - loaded')

    }, [loaded, dispatch, id]);

    useEffect(() => {
        if (!storeJobData) return;
        let newObj = {
            ...{
                title: "",
                description: "",
                photos: [],
                date: "",
                timeEstimate: 1,
                address: "",
                category: "",
                hourlyRate: 180,
                startFee: 0
            },
            ...storeJobData,
            ...localJobData
        };
        if (typeof newObj.date === 'object') {
            let date = newObj.date.toDate(),
                y = date.getFullYear(),
                m = date.getMonth() + 1,
                d = date.getDate()
            if (m < 10) { m = `0${m}` }
            if (d < 10) { d = `0${d}` }
            newObj.date = `${y}-${m}-${d}`;
        }

        setJobData(newObj);
    }, [storeJobData, localJobData])



    const handleRadioChange = (e) => {
        setJobRadio(e.target.value);
        if (e.target.value === 'other') {
            setJobData({
                ...jobData,
                title: ''
            })
        } else {
            setJobData({
                ...jobData,
                title: jobs[jobData.category][e.target.value].label
            })
        }
    }

    const handleInput = (e) => {
        let tmp = { ...jobData };
        tmp[e.target.name] = e.target.value;
        setLocalJobData(tmp)
    }
    const handleCatChange = (e) => {
        setJobRadio("")
        handleInput(e)
    }
    const handleUpload = ({ key, downloadURL }) => {
        let tmp = { ...jobData };
        tmp.photos[key] = downloadURL;
        setLocalJobData(tmp)
    }
    const handleEstimateChange = (e) => {
        let tmp = { ...jobData };
        tmp.timeEstimate = parseInt(e.target.value)
        setLocalJobData(tmp)
    }
    const handleSlide = (e, newValue) => {
        let tmp = { ...jobData };
        tmp.hourlyRate = newValue;
        setLocalJobData(tmp)
    }
    const handleSave = (target = false) => {
        setLoader(true);

        let errs = {
            title: false,
            description: false,
            date: false,
        }

        if (!jobData.title || jobData.title.length < 3) {
            errs.title = 'Feltet er påkrevd';
        }
        // if( !jobData.description || jobData.description.length < 3 ) {
        //     errs.description = 'Feltet er påkrevd';
        // }
        if (!jobData.date || jobData.date.length < 3) {
            errs.date = 'Feltet er påkrevd';
        }

        setErrors(errs);

        if (Object.keys(errs).some(e => errs[e])) {
            setLoader(false);
            return false;
        }

        if (typeof jobData.date === 'string') {
            jobData.date = firebase.firestore.Timestamp.fromDate(new Date(jobData.date));
        }

        // return;
        firebase.firestore().doc(`jobs/${id}`).update(jobData)
            .then(() => {
                if (target) {
                    history.push(target);
                }
            })
            .catch(err => {
                logger(err);
                setLoader(false)
            });

        setLoader(false)
        tracker.logEvent('Edit job - job updated')
    }

    if (!loaded || loader || !jobData || Object.keys(jobData).length === 0) return <Loader />

    const estimates = (time, rate, startFee = 0) => {
        let res = {
            estTime: `3+ timer`,
            estPayout: `${(rate * 3) + startFee}+`
        }
        if (time === 1) {
            res.estTime = `1 time`
            res.estPayout = `${(rate * 1) + startFee}`
        }
        if (time === 2) {
            res.estTime = `1-3 timer`
            res.estPayout = `${(rate * 1) + startFee} - ${(rate * 3) + startFee}`
        }
        return res
    }
    const { estTime, estPayout } = estimates(jobData.timeEstimate, jobData.hourlyRate, jobData.startFee);

    const defaultRate = storeJobData.hourlyRate || 180;

    return (
        <>
            <FormControl
                variant="outlined"
                fullWidth
            // disabled={true}

            >
                <InputLabel id="category-label" shrink={true}>Kategori</InputLabel>
                <Select
                    labelId="category-label"
                    id="category"
                    value={jobData.category}
                    onChange={handleCatChange}
                    name="category"
                >
                    {Object.keys(categories).map((key) => {
                        let obj = categories[key];
                        return (<MenuItem value={obj.key} key={obj.key}>{obj.label}</MenuItem>)
                    })}
                </Select>
            </FormControl>

            <FormLabel component="legend" style={{ marginTop: 20 }}>Hva ønsker du hjelp til?</FormLabel>
            <FormControl component="fieldset">
                <RadioGroup value={jobRadio} onChange={handleRadioChange}>
                    {Object.keys(jobs[jobData.category]).map((key) => {
                        let obj = jobs[jobData.category][key];
                        return (<FormControlLabel value={obj.key} control={<Radio />} key={obj.key} label={obj.label} />)
                    })}
                    <FormControlLabel value={`other`} control={<Radio />} key={`other`} label={`Annet`} />
                </RadioGroup>
            </FormControl>


            <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                autoComplete='off'
                name="title"
                placeholder={`Vaske vinduer`}
                label={`Tittel`}
                value={jobData.title}
                onChange={handleInput}
                error={!!(errors.title)}
                helperText={errors.title || ''}

                style={{
                    display: (jobRadio === 'other' ? 'block' : 'none')
                }}
            />
            <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                autoComplete='off'
                name="description"
                placeholder={`Beskrivelse av jobben`}
                label={`Beskrivelse`}
                multiline
                rows={4}
                value={localJobData ? localJobData.description : jobData.description}
                onChange={handleInput}
                error={!!(errors.description)}
                helperText={errors.description || ''}

            />
            <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                autoComplete='off'
                type="date"
                name="date"
                inputProps={{
                    min: todayPlus(1)
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                // placeholder={`Jeg ønsker jobben utført innen`}
                label={`Jeg ønsker jobben utført innen`}
                value={jobData.date}
                onChange={handleInput}
                error={!!(errors.date)}
                helperText={errors.date || ''}

            />

            <RadioGroup aria-label="time estimate" name="timeEstimate" value={jobData.timeEstimate} onChange={handleEstimateChange} >
                <Grid container spacing={3} style={{ textAlign: 'center', marginTop: 20 }}>
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            Hvor lang tid regner du med at det tar?
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel value={1} control={<Radio />} label="Under 1 time" labelPlacement="bottom" />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel value={2} control={<Radio />} label="1-3 timer" labelPlacement="bottom" />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel value={3} control={<Radio />} label="Over 3 timer" labelPlacement="bottom" />
                    </Grid>
                </Grid>
            </RadioGroup>

            <Grid container spacing={3}>
                <Grid item xs={12} style={{ marginTop: 30 }}>
                    <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        Hvor mye vil du betale per time?
                    </Typography>
                    <Slider
                        name="hourlyRate"
                        onChange={handleSlide}
                        defaultValue={defaultRate}
                        step={10}
                        marks={[
                            {
                                value: 90,
                                label: '90kr'
                            },
                            {
                                value: 100,
                                label: '100kr'
                            },
                            {
                                value: 150,
                                label: '150kr'
                            },
                            {
                                value: 200,
                                label: '200kr'
                            }
                        ]}
                        min={90}
                        max={200}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={4} className={classes.highlightWrapper}>
                    <Typography variant="h3" className={classes.highlightTitle}>
                        {jobData.hourlyRate}
                    </Typography>
                    <Typography variant="body1" className={classes.highlightCaption}>
                        kr/t<br />
                        {/* {job.startFee && `${job.startFee} Forsikring/tjenestegebyr`} */}
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.highlightWrapper}>
                    <Typography variant="h3" className={classes.highlightTitle}>
                        {estTime}
                    </Typography>
                    <Typography variant="body1" className={classes.highlightCaption}>
                        Forventet tidsbruk
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.highlightWrapper}>
                    <Typography variant="h3" className={classes.highlightTitle}>
                        {estPayout}
                    </Typography>
                    <Typography variant="body1" className={classes.highlightCaption}>
                        Forventet Betaling
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={3} style={{
                textAlign: 'center',
                marginTop: 20
            }}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Legg ved bilder av jobben
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Bilde av jobben gir deg mer oppmerksomhet og flere søknader. Klikk på kameraikonene nedenfor for å laste opp inntil tre bilder.
                    </Typography>
                </Grid>
                {Array.from(Array(3).keys()).map((i, k) => (
                    <Grid item xs={4}
                        key={k}
                    >
                        <Box style={{
                            height: 150,
                            backgroundImage: `url(${jobData.photos[k] || ''})`,
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            backgroundColor: '#ECECEC',
                            position: 'relative'
                        }}>
                            <Box style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0
                            }}>
                                {jobData.photos[k] ?
                                    <Fab
                                        size="small"
                                        onClick={() => handleUpload({ key: k, downloadURL: null })}
                                    >
                                        <DeleteIcon />
                                    </Fab>
                                    :
                                    <UploadPhoto
                                        path={`/jobs/${userData.uid}/${id}/${k}-`}
                                        onComplete={({ downloadURL }) => {
                                            handleUpload({ key: k, downloadURL })
                                        }}
                                    />
                                }
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                autoComplete='off'
                name="address"
                placeholder={`Adresse`}
                label={`Adresse`}
                value={jobData.address}
                onChange={handleInput}
                style={{ marginTop: 40 }}
            />
            {!jobData.terms || jobData.status === 'draft' ?
                // Not published
                <Grid container spacing={3} style={{ marginTop: 20 }}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => {
                                tracker.logEvent('Edit job - Save draft')
                                handleSave('/')
                            }}
                            fullWidth
                        >
                            Lagre kladd
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                tracker.logEvent('Edit job - Save and publish')
                                handleSave(`/${id}/publish/`)
                            }}
                            fullWidth
                        >
                            Publiser
                        </Button>
                    </Grid>
                </Grid>
                :
                // Published
                <Grid container spacing={3} style={{ marginTop: 20 }}>
                    <Grid item xs={6}>

                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                tracker.logEvent('Edit job - Update')
                                handleSave(`/${id}/publish/`)
                            }}
                            fullWidth
                        >
                            Oppdater
                        </Button>
                    </Grid>
                </Grid>
            }
        </>
    );
}
const todayPlus = (plus = 0) => {
    let o = new Date();
    o.setDate(o.getDate() + plus);
    let y = o.getFullYear(),
        m = o.getMonth() + 1,
        d = o.getDate();
    if (d < 10) {
        d = `0${d}`;
    }
    if (m < 10) {
        m = `0${m}`;
    }
    return `${y}-${m}-${d}`;
}


export default EditJob;