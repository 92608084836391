import { Container, Grid } from "@material-ui/core";

const CenterFrame = ({ children, style = {}, maxWidth = "xs" }) => {
    return (
        <Container
            style={{ overflow: 'auto', maxHeight: '100vh', ...style }}
        >
            <Container
                maxWidth={maxWidth}
            >
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{
                        height: '100vh',

                    }}
                >
                    <Grid item xs={12}
                        style={{
                            textAlign: 'center',
                            paddingBottom: 70,
                            paddingTop: 70,
                        }}
                    >
                        {children || ''}
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}

export default CenterFrame;