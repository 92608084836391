import React from 'react';
import { useSelector } from 'react-redux';
import ListSection from '../../Views/Jobs/ListSection';
import Loader from '../../Views/Loader';

const MyJobs = ({status = [], title }) => {
    const userData = useSelector(state => state.user.userData);
    const loaded = useSelector(state => state.jobs.jobsLoaded);
    const jobs = useSelector(state => state.jobs.jobs);

    if( !loaded ) return <Loader />

    if( !jobs || Object.keys(jobs).length === 0) return null;

    let items = Object.keys(jobs).map(key => {
        let job = jobs[key];
        if (job.assigned_to === userData.uid && status.includes(job.status)) return jobs[key]
        return false;
    });

    if (!items || items.length === 0) return null;

    return (
        <>
            <ListSection title={title} items={items} />
        </>
    )
}
 
export default MyJobs;