import { applyMiddleware, createStore, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import user from './user'
import gui from './gui'
import jobs from './jobs'
import helper from './helper'

const store = createStore(combineReducers({
    helper,
    user,
    gui,
    jobs
}), applyMiddleware(thunkMiddleware));

export default store;