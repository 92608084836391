import { AppBar, IconButton, makeStyles, Modal, Toolbar, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { watchInterestedUsers } from '../../redux/jobs';
import Loader from '../../Views/Loader';
import Chat from '../Jobs/Chat';
import AssignJobButton from './AssignJobButton';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const ChatModal = ({handleClose}) => {
    const history = useHistory()
    const classes = useStyles();
    const { id = false, uid } = useParams();
    const job = useSelector(state => state.jobs.jobs[id]);
    const dispatch = useDispatch();
    
    var interestedUsers = useSelector(state => state.jobs.interestedUsers);
    const me = useSelector(state => state.user.userData);
    var user = false;


    if( me.uid === uid ) {
        user = { user: me };
    } else {
        if( interestedUsers[id] ) {
            interestedUsers = interestedUsers[id]
        } else {
            interestedUsers = {};
        }
        if( interestedUsers[uid] ) {
            user = interestedUsers[uid]
        }
    }

    useEffect(() => {
        if( !user ) {
            dispatch(
                watchInterestedUsers(id)
            )
        }
    }, [dispatch, id, user])

    if( !user || !job ) return <Loader />
    
    const handleModalClose = () => {
        history.push(`/${id}`);
    }

    return (
        <Modal
            open={true}
            onClose={handleModalClose}
        >
            <>
            <AppBar position="fixed" color="default">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="back" onClick={handleModalClose}>
                        <BackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        
                    </Typography>
                    <AssignJobButton id={id} uid={uid} />
                </Toolbar>
            </AppBar>
            <Chat jobId={id} employee={uid} />
            </>
        </Modal>
    )
}

export default ChatModal;