import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router';
import { watchJob } from '../../redux/jobs';
import TopBar from '../../Views/Common/TopBar';
import Loader from '../../Views/Loader';
import EditJob from '../Jobs/EditJob';
import PublishJob from '../Jobs/PublishJob';
import Users from '../Jobs/Users';
import ViewJob from '../Jobs/ViewJob';
import EmployerActions from './Actions';
import ChatModal from './ChatModal';
import tracker from '../../Helpers/tracker';
import { watchInterestedUsers } from '../../redux/jobs';


const SingleJob = (props) => {
    const { id = false, action = 'view' } = useParams();
    const [trackedID, setTrackedID] = useState(null);
    const job = useSelector(state => state.jobs.jobs[id])
    const user = useSelector(state => state.user.userData);
    const interestedUsers = useSelector(state => {
        if (!job || !job.id) return false;
        return (state.jobs.interestedUsers[job.id] || false)
    })
    const dispatch = useDispatch();

    useEffect(() => {
        if (!job || id === trackedID) return;
        tracker.logEvent('Show job', { jobId: id })
        setTrackedID(id);
    }, [id, job, trackedID, setTrackedID])

    useEffect(() => {
        if (!job) return;
        if (!interestedUsers) {
            dispatch(watchInterestedUsers(job.id))
        }
    }, [job, dispatch, interestedUsers])

    if (job === undefined) {
        dispatch(watchJob(id));
        return (<Loader />)
    }


    const backPath = (action === 'view' ? `/` : `/${id}/`);
    const backButton = (!!backPath);

    return (
        <>
            <TopBar backPath={backPath} backButton={backButton} />
            <Container
                maxWidth="sm"
                style={{
                    marginBottom: 100
                }}
            >
                {user.type === 'employer' && job && action !== 'chat' &&
                    <EmployerActions job={job} />
                }
                <Switch>
                    <Route path="/:id/edit">
                        <EditJob />
                    </Route>
                    <Route path="/:id/publish">
                        <PublishJob />
                    </Route>
                    <Route path="/:id/chat/:uid">
                        <ChatModal />
                    </Route>
                    <Route path="/:id/users">
                        <Users />
                    </Route>
                    <Route>
                        <ViewJob />
                    </Route>
                </Switch>
            </Container>
        </>
    );
}

export default SingleJob;