import { Typography } from '@material-ui/core';
// import { Grid, ButtonGroup, Button } from '@material-ui/core';
import React from 'react';
// import { useParams } from 'react-router';
// import { NavLink } from 'react-router-dom';
import BottomBar from '../../../Views/Common/BottomBar';
import Users from '../../Jobs/Users';

const Published = ({ job }) => {
    // const { id = false, action = 'view' } = useParams();



    return (
        <BottomBar>
            {/* <Grid item xs={6}
                // className={classes.actionButtons}
            >
                <ButtonGroup color="primary" variant="text" aria-label="navigate job obtions">
                    <Button
                        variant={action === 'view' ? "contained" : ""}
                        component={NavLink}
                        to={`/${id}`}
                        >
                        Vis
                    </Button>

                    <Button
                        variant={action === 'users' ? "contained" : ""}
                        component={NavLink}
                        to={`/${id}/users`}
                        >
                        Søknader
                    </Button>
                </ButtonGroup>
            </Grid> */}
            <Typography variant="h3">Søkere</Typography>
            <Users />
        </BottomBar>
    )
}

export default Published