import { Badge, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import firebase from '../../../config/firebase';
import tracker from '../../../Helpers/tracker';
import FullscreenJobView from '../../../Views/Common/FullscreenJobView';
import RejectDialog from '../../../Views/Jobs/RejectDialog';
import UserWidget from '../../../Views/Users/UserWidget';

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        textAlign: 'center'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}));

const Completed = ({ job }) => {
    const me = useSelector(state => state.user.userData);
    const assignee = useSelector(state => state.jobs.jobInterest[job.id])

    const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
    const classes = useStyles();
    // const { id = false, action = 'view' } = useParams();

    let unread = 0;
    if (
        assignee
        && assignee.chat
        && assignee.chat.count
        && assignee.chat.count > 0
        && (
            !assignee.chat[me.uid]
            || assignee.chat.count > assignee.chat[me.uid]
        )
    ) {
        unread = (assignee.chat.count - assignee.chat[me.uid]);
    }

    const handleRejectDialogOpen = () => {
        setRejectDialogOpen(true)
    }
    const handleRejectDialogClose = () => {
        setRejectDialogOpen(false)
    }
    const handlePaymentReceived = () => {
        firebase.firestore().doc(`jobs/${job.id}`).update({
            status: 'paid'
        })
        tracker.logEvent('Payment received', { jobId: job.id })
    }
    return (
        <>
            <FullscreenJobView>
                <Grid item xs={6}>
                    <UserWidget uid={job.owner} />
                </Grid>
                <Grid item xs={6}>
                    <UserWidget uid={me.uid} />
                </Grid>
                <Grid item xs={12} style={{ color: '#fff', textAlign: 'left' }}>
                    <Typography variant="h4" gutterBottom>Venter på betaling fra {job.owner_name}.</Typography>
                    <Typography variant="body1" gutterBottom>
                        Når du mottar pengene, bekreft nedenfor slik at vi får lukket jobben.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Det kan ta litt tid, men om du ikke mottar pengene innen relativt kort tid. Klikk på knappen nedenfor så tar vi kontakt med dere for å ordne opp. Du kan også forsøke å sende en melding og spørre om {job.owner_name} har glemt det.
                    </Typography>

                </Grid>
                <Grid item xs={12} className={classes.actionButtons}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePaymentReceived}
                        fullWidth
                        size="large"
                    >
                        Jeg har fått pengene!
                    </Button>
                </Grid>
                <Grid item xs={12} className={classes.actionButtons}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleRejectDialogOpen}
                        fullWidth
                        size="large"
                    >
                        Jeg har ikke fått pengene
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Badge
                        badgeContent={unread}
                        max={9}
                        color="error"
                        style={{ width: '100%' }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            aria-label="open chat"
                            component={NavLink}
                            to={`/${job.id}/chat/${me.uid}`}
                            fullWidth
                            size="large"
                        >
                            <ChatIcon className={classes.extendedIcon} />
                            Meldinger
                        </Button>
                    </Badge>
                </Grid>
            </FullscreenJobView>
            <RejectDialog dialogOpen={rejectDialogOpen} handleDialogClose={handleRejectDialogClose} job={job} />
        </>
    )
}



export default Completed;